import React from "react"
import { Controller } from "react-hook-form"
import Switch from "../../../../../components/atoms/switch"
import EmailTemplateHover from "../../../../../components/molecules/email-template-hover"
import IndeterminateCheckbox from "../../../../../components/molecules/indeterminate-checkbox"
import InputField from "../../../../../components/molecules/input"
import { NextSelect } from "../../../../../components/molecules/select/next-select"
import FormValidator from "../../../../../utils/form-validator"
import { NestedForm } from "../../../../../utils/nested-form"

export type DigitalProductEmailIdType = {
    digitalProductEmailId: string

}

type DigitalProductEmailIdFormProps = {
    form: NestedForm<DigitalProductEmailIdType>
}

/**
 * Re-usable nested form used to submit customs information for products and their variants.
 * @example
 * <HiddenForm form={nestedForm(form, "hidden")} />
 */
const DigitalProductEmailIdForm = ({ form }: DigitalProductEmailIdFormProps) => {
    const {
        register,
        path,
        control,
        formState: { errors },
    } = form


    return (
        <div>
            <div className="flex items-center justify-between mb-2xsmall">
                <EmailTemplateHover>
                    <h2 className="inter-base-semibold">Digital Product Email Id</h2>
                </EmailTemplateHover>
                <InputField
                    label="Digital Product Email Id"
                    placeholder="d-abc123"
                    {...register(path("digitalProductEmailId"), {
                        pattern: FormValidator.whiteSpaceRule("digitalProductEmailId"),
                    })}
                    errors={errors}
                />
            </div>
        </div>
    )
}

export default DigitalProductEmailIdForm
