import { Order } from "@medusajs/medusa"
import { Link } from "@reach/router"
import { ProductVariant } from "medusa-react/dist/types"
import React from "react"
import { BasicStat, StatRow, TimeCompaireDisplayStat } from "../../../components/organisms/display-stat"
import { GenralDashboardData } from "../../../types/shared"
import SimpleTable from "../table"


type GenralDataProps = {
    data: GenralDashboardData | undefined
}

const GenralData = ({ data }: GenralDataProps) => {
    const renderProductTable = (data: ProductVariant[], title) => {
        return (
            <SimpleTable
                title={title}
                className="col-span-5"

                heads={["",
                    "Variant",
                    "Product",
                    "Left In Stock",]}>
                {data.map((product, index) => (
                    <tr key={index}>
                        <th>{index + 1}</th>
                        <td>
                            <Link to={`/a/products/${product.product.id}`} className="link link-primary">
                                {product.title}
                            </Link>
                        </td>
                        <td>
                            <Link to={`/a/products/${product.product.id}`} className="link link-primary">
                                {product.product.title}
                            </Link>
                        </td>
                        <td>{product.inventory_quantity}</td>
                    </tr>
                ))}
            </SimpleTable>
        )
    }
    const renderOrderTable = (data: Order[], title) => {
        return (
            <SimpleTable
                className="col-span-5"
                title={title}
                heads={["",
                    "Id",
                    "Status",
                    "total",
                    "Date",
                ]}>
                {data.map((order, index) => (
                    <tr>
                        <th>{index + 1}</th>
                        <td>
                            <Link to={`/a/orders/${order.id}`} className="link link-primary">
                                {order.display_id}
                            </Link>
                        </td>
                        <td>
                            {order.status}
                        </td>
                        <td>
                            ${Math.round(order.total / 100)}
                        </td>
                        <td>
                            {order.created_at}
                        </td>

                    </tr>
                ))}

            </SimpleTable>
        )
    }
    if (data) {
        return (
            <div className="grid gap-4 grid-cols-12 grid-rows-2">

                <div className="row-start-1 row-end-3 flex col-span-2">
                    <StatRow vertical>
                        <BasicStat stat={data.openOrdersCount} displayAsCurrency={false} title="Open Orders" />
                        <BasicStat stat={data.openAssembliesCount} displayAsCurrency={false} title="Open Assemblies" />
                        <BasicStat stat={data.lowStockProducts.length} displayAsCurrency={false} title="# Low Stock Products" />
                        <BasicStat stat={data.outOfStockProducts.length} displayAsCurrency={false} title="# Low Stock Products" />
                    </StatRow>
                </div>

                {renderProductTable(data.outOfStockProducts, "Products Out Of Stock")}
                {renderProductTable(data.lowStockProducts, "Products Low on Stock")}

                {renderOrderTable(data.openOrders, "Open Orders")}
                {renderOrderTable(data.openAssemblies, "Open Assemblies")}
            </div>
        )
    } else {
        return (
            <>
                loading
            </>
        )
    }

}

export default GenralData
