import { Link } from "gatsby"
import React from "react"
import SimpleTable from "../table"
import { CreateOrderTrackerInput, OrderTracker, OrderTrackerStatus } from "../../../types/shared"
import Medusa from "../../../services/api"
import OrderTrackerModal from "./orderTracker-modal"
import Button from "../../../components/fundamentals/button"
import moment from "moment"

const OrderTrackerView = () => {
    const [orders, setOrders] = React.useState<OrderTracker[]>([]),
        [showEdit, setShowEdit] = React.useState(false),
        [edit, setEdit] = React.useState<OrderTracker>()

    const getOrders = async () => {
        const orders = await Medusa.customRoutes.getOrderTracker()
        setOrders(orders.data)
    }
    React.useEffect(() => {
        getOrders()
    }, [])

    const handelSubmit = async (data: CreateOrderTrackerInput) => {
        if (edit) {
            await Medusa.customRoutes.updateOrderTracker(edit.id, data)
        } else {
            await Medusa.customRoutes.createOrderTracker(data)
        }
        setShowEdit(false)
        setEdit(undefined)
        getOrders()

    }
    const renderStatus = (data: OrderTracker) => {
        console.log(data)
        switch (data.status) {
            case OrderTrackerStatus.NEEDS_TO_ORDER:
                return (<div className="badge badge-error">{data.status}</div>)
            case OrderTrackerStatus.ORDERED:
                return (<div className="badge badge-success">{data.status}</div>)
            case OrderTrackerStatus.SHIPPED:
                return (<div className="badge badge-success tooltip" data-tip="Shipping Link">
                    {/* @ts-ignore */}
                    <a href={data.metadata?.trackingLink ? data.metadata.trackingLink : ""} target="_blank" rel="noopener noreferrer">
                        {data.status}
                    </a>
                </div>)
            case OrderTrackerStatus.RECIEVED:
                return (<div className="badge badge-warning">{data.status}</div>)
            case OrderTrackerStatus.NEED_TO_BE_LISTED:
                return (<div className="badge badge-warning">{data.status}</div>)

            default:
                break;
        }
    }

    return (
        <>
            <Button variant={"primary"} onClick={() => setShowEdit(true)}
            >Track new order</Button>
            <SimpleTable
                className="col-span-5 mt-4 mb-4"
                title={"Order Tracker"}
                heads={["edit",
                    "Product",
                    "Status",
                    "Date",
                    "oos",
                    "live Product",
                    "Delete"
                ]}>
                {orders.map((order, index) => (
                    <tr onClick={e => {
                        setEdit(order)
                        setShowEdit(true)
                    }}>
                        <th > {index + 1}</th>
                        <td>
                            {
                                order.metadata && order.metadata.productLink ? (
                                    //@ts-ignore
                                    <Link to={order.metadata.productLink} className="link link-primary">
                                        {order.title}
                                    </Link>
                                ) :
                                    order.title
                            }

                        </td>
                        <td>
                            {renderStatus(order)}
                        </td>
                        <td>
                            {moment(order.created_at).format("DD/MM/YYYY")}
                        </td>
                        <td>
                            {order.oos ? "🔥ORDER🔥" : "🤙Its ok🤙"}
                        </td>

                        <td>
                            {order.currentlySold ? "✅ sold / listed" : "❌ not sold / listed"}
                        </td>
                        <td>
                            <Button variant={"danger"} onClick={(e) => {
                                e.preventDefault()

                                Medusa.customRoutes.deleteOrderTracker(order.id)
                            }}

                            >delete</Button>
                        </td>
                    </tr>
                ))}

            </SimpleTable>
            {showEdit && (
                <OrderTrackerModal
                    onClose={() => {
                        setShowEdit(!showEdit)
                        setEdit(undefined)
                    }}
                    handelSubmit={handelSubmit}
                    orderTracker={edit}
                    open={showEdit} />
            )}
        </>
    )
}
export default OrderTrackerView