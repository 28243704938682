import React from "react";
import Table from "../../../../../components/molecules/table";
import { KitConfig } from "../../../../../types/shared";

type KitConFigProps = {
    kitConfig: KitConfig
    index: number
}
export const KitConfigView = ({ kitConfig, index }: KitConFigProps) => {
    const productsToView = kitConfig.products ? kitConfig.products : kitConfig.kitConfigProducts
    return (
        <div className="flex flex-col gap-y-xsmall">
            <h2>#{index + 1}</h2>
            <div className="flex justify-between items-center inter-base-regular text-grey-50">
                <p>Title</p>
                <p>{kitConfig.title}</p>
            </div>
            <div className="flex justify-between items-center inter-base-regular text-grey-50">
                <p>Must Select</p>
                <p>{kitConfig.mustSelect ? "True" : "False"}</p>
            </div>
            <div className="flex justify-between items-center inter-base-regular text-grey-50">
                <p>Select One</p>
                <p>{kitConfig.selectOne ? "True" : "False"}</p>
            </div>

            <Table className="table-fixed">
                <Table.Head>

                    <Table.HeadRow>

                        <Table.HeadCell>
                            Product Name
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Sub-title
                        </Table.HeadCell>

                        <Table.HeadCell>
                            qty Needed
                        </Table.HeadCell>
                        <Table.HeadCell>
                            pre-selected
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Product Id
                        </Table.HeadCell>
                    </Table.HeadRow>
                </Table.Head>
                <Table.Body>
                    {productsToView.map((product => (
                        <Table.Row color={"inherit"}>
                            <Table.Cell>
                                {product.product_name}
                            </Table.Cell>
                            <Table.Cell>
                                {product.subTitle}
                            </Table.Cell>

                            <Table.Cell>
                                {product.qtyNeeded}
                            </Table.Cell>
                            <Table.Cell>
                                {product.preSelected ? "True" : "False"}
                            </Table.Cell>
                            <Table.Cell className={"overflow-hidden	"}>
                                {product.product_id}
                            </Table.Cell>
                        </Table.Row>
                    )))}

                </Table.Body>
            </Table>
        </div>
    );
};

export default KitConfigView