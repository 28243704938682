import React from "react"

import Button from "../../../fundamentals/button"
import Modal from "../../../molecules/modal"
import KitConfigProductForm from "./kit-config-product-form"
import { CreateKitConfigProductInput, KitConfigProduct } from "../../../../types/shared"


type Props = {
  kitconfigProduct?: KitConfigProduct
  open: boolean
  onClose: () => void
  handelSubmit: (kitconfigProduct: CreateKitConfigProductInput) => void
}


const KitConfigProductModal = ({ kitconfigProduct, open, onClose, handelSubmit }: Props) => {


  const onSubmit = (kitconfigProduct: CreateKitConfigProductInput) => {
    handelSubmit(kitconfigProduct)
  }

  return (
    <Modal open={open} handleClose={onClose} isLargeModal>
      <Modal.Body>
        <Modal.Header handleClose={onClose}>
          <h1 className="inter-xlarge-semibold m-0">
            Edit General Information
          </h1>
        </Modal.Header>
        <Modal.Content>
          <KitConfigProductForm kitconfigProduct={kitconfigProduct} propsOnSubmit={
            onSubmit
          } />
        </Modal.Content>
        <Modal.Footer>
          <div className="flex gap-x-2 justify-end w-full">
            <Button
              size="small"
              variant="secondary"
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>

          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}


export default KitConfigProductModal
