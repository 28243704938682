import Medusa from "../../../services/api"
import React, { useEffect, useState } from "react"
import { BasicStat, StatRow } from "../../../components/organisms/display-stat"
import { DashboardGraphData } from "../../../types/shared"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


type GraphsProps = {
    salesData: DashboardGraphData | undefined
}

const Graphs = ({ salesData }: GraphsProps) => {

    const getAvgSales = () => {
        if (salesData) {
            const avgSales = salesData.salesByDay.reduce((acc, cur) => {
                return acc + cur.total
            }, 0)
            return avgSales / salesData.salesByDay.length
        } else {
            return 0
        }
    }
    const getAvgOrderValue = () => {
        if (salesData) {
            const allOrders = Object.values(salesData.ordersByDay).flat()
            const avgSales = allOrders.reduce((acc, cur) => {
                return acc + cur.total
            }, 0)
            return avgSales / allOrders.length
        } else {
            return 0
        }
    }

    const getAvgOrderPerDay = () => {
        if (salesData) {
            const allOrders = Object.values(salesData.ordersByDay).map(day => day.length)
            const avgSales = allOrders.reduce((acc, cur) => {
                return acc + cur
            }, 0)
            return avgSales / allOrders.length
        } else {
            return 0
        }
    }
    const getMostOrderInDay = () => {
        if (salesData) {
            const allOrders = Object.values(salesData.ordersByDay).map(day => day.length)
            let mostOrdersDay = 0
            for (const order of allOrders) {
                if (order > mostOrdersDay) {
                    mostOrdersDay = order
                }
            }
            return mostOrdersDay
        } else {
            return 0
        }
    }
    const getMostSalesInDay = () => {
        if (salesData) {
            let highestSalesDay = 0
            for (const sales of salesData.salesByDay) {
                if (sales.total > highestSalesDay) {
                    highestSalesDay = sales.total
                }
            }
            return highestSalesDay
        } else {
            return 0
        }
    }

    if (salesData) {
        return (
            <div className="grid gap-4 grid-cols-12 grid-rows-2">

                <div className="row-start-1 row-end-3 flex col-span-2">
                    <StatRow vertical>
                        <BasicStat stat={getAvgSales()} displayAsCurrency={true} title="avg. sales per day" />
                        <BasicStat stat={getAvgOrderValue()} displayAsCurrency={true} title="avg. order value" />
                        <BasicStat stat={getAvgOrderPerDay()} displayAsCurrency={false} title="avg. orders per day" />
                        <BasicStat stat={getMostOrderInDay()} displayAsCurrency={false} title="most orders in one day" />
                        <BasicStat stat={getMostSalesInDay()} displayAsCurrency={true} title="most sales in one day" />

                    </StatRow>
                </div>
                <div className="row-start-1 row-end-3 flex col-span-10">
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={800}
                            height={300}
                            data={salesData.salesByDay}

                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} />
                            {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                        </LineChart>
                    </ResponsiveContainer>

                </div>       </div>
        )
    } else {
        return (
            <>
                loading
            </>
        )
    }

}

export default Graphs
