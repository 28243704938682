import { Link } from "@reach/router"
import React from "react"
import { TimeCompaireDisplayStat, StatRow } from "../../../components/organisms/display-stat"
import { GenralDashboardData, RegionSalesData, ThinProductVariantSalesData, TimeDashboardData } from "../../../types/shared"
import SimpleTable from "../table"


type GenralDataProps = {
    data: TimeDashboardData | undefined
}

const TimeData = ({ data }: GenralDataProps) => {
    const renderProductTable = (data: ThinProductVariantSalesData[], title) => {
        return (
            <SimpleTable
                title={title}
                className="col-span-5"

                heads={["Rank", "# Sold", "Product", "# Stock"]}>
                {data.map((product, index) => (
                    <tr key={index}>
                        <th>{index + 1}</th>
                        <td>{product.count}</td>
                        <td><Link to={`/a/products/${product.thinVariant.id}`} className="link link-primary">
                            {product.thinVariant.productTitle} : {product.thinVariant.title}
                        </Link></td>
                        <td>{product.thinVariant.inventory_quantity}</td>
                    </tr>
                ))}
            </SimpleTable>
        )

    }
    const renderRegionTable = (data: RegionSalesData[], title) => {
        return (
            <SimpleTable
                title={title}
                className="col-span-5"

                heads={["Rank", "# sales", "name"]}>
                {data.map((region, index) => (
                    <tr>
                        <th>{index + 1}</th>
                        <td>{region.count}</td>
                        <td><Link to={`/a/settings/regions/${region.region.id}`} className="link link-primary">
                            {region.region.name}
                        </Link></td>

                    </tr>
                ))}
            </SimpleTable>
        )

    }

    if (data) {
        return (
            <>
                <div className="grid gap-4 grid-cols-12 grid-rows-2">

                    <div className="row-start-1 row-end-3 flex col-span-2">
                        <StatRow vertical>
                            <TimeCompaireDisplayStat currentNumber={data.totalSales} pastNumber={data.legacyTotalSales} displayAsCurrency={true} title={"Total Sales"} />
                            <TimeCompaireDisplayStat currentNumber={data.avgOrderValue} pastNumber={data.legacyAvgOrderValue} displayAsCurrency={true} title={"Avg Order Value"} />
                            <TimeCompaireDisplayStat currentNumber={data.shippingCollected} pastNumber={data.legacyShippingCollected} displayAsCurrency={true} title={"Shipping Collected"} />
                            <TimeCompaireDisplayStat currentNumber={data.newCustomerCount} pastNumber={data.legacyNewCustomerCount} displayAsCurrency={false} title={"New Users"} />
                            <TimeCompaireDisplayStat currentNumber={data.orderCount} pastNumber={data.legacyOrderCount} displayAsCurrency={false} title={"Order Count"} />
                        </StatRow>

                    </div>

                    {renderProductTable(data.topSellingProducts, "Top Selling Products This Period")}
                    {renderProductTable(data.legacyTopSellingProducts, "Top Selling Products Last Period")}

                    {renderRegionTable(data.topRegion, "Top Selling Region This Period")}
                    {renderRegionTable(data.legacyTopRegion, "Top Selling Region Last Period")}
                </div>

            </>
        )
    } else {
        return (
            <>
                loading
            </>
        )
    }

}

export default TimeData
