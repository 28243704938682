import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import { useAdminProduct } from "medusa-react"
import React, { useEffect, useState } from "react"
import ReactJson from "react-json-view"
import BackButton from "../../../components/atoms/back-button"
import Spinner from "../../../components/atoms/spinner"
import Section from "../../../components/organisms/section"
import { getErrorStatus } from "../../../utils/get-error-status"
import AttributesSection from "./sections/attributes"
import GeneralSection from "./sections/general"
import MediaSection from "./sections/media"
import RawSection from "./sections/raw"
import KitConfigSection from "./sections/kitConfig"
import ThumbnailSection from "./sections/thumbnail"
import VariantsSection from "./sections/variants"
import { ExtendedProduct } from "../../../types/shared"
import { ProductErrorLevel, ProductTestResult, productTest } from "../../../utils/product-test"
import Medusa from "../../../services/api"
import DataSection from "./sections/data"


interface EditProps extends RouteComponentProps {
  id?: string
}

const Edit = ({ id }: EditProps) => {
  const { product, status, error } = useAdminProduct(id || ""),
    [productErrors, setProductErrors] = useState<ProductTestResult[]>([])
  useEffect(() => {
    if (product) {
      const tempErrors = productTest(product as ExtendedProduct)
      console.log("tempErrors", tempErrors)
      setProductErrors(tempErrors)
    }

  }, [product])

  if (error) {
    let message = "An unknown error occurred"

    const errorStatus = getErrorStatus(error)

    if (errorStatus) {
      message = errorStatus.message

      if (errorStatus.status === 404) {
        navigate("/404")
        return null
      }
    }

    // temp needs design
    return (
      <Section title="Error">
        <p className="inter-base-regular">{message}</p>

        <div className="mt-base bg-grey-5 rounded-rounded px-base py-xsmall">
          <ReactJson
            name={"Stack Trace"}
            collapsed={true}
            src={JSON.parse(JSON.stringify(error))}
          />
        </div>
      </Section>
    )
  }
  const listOnEtsy = async () => {
    if (product) {
      const res = await Medusa.customRoutes.listProductOnEtsy(product.id)
    } else {
      console.log("no products")
    }

  }

  if (status === "loading" || !product) {
    // temp, perhaps use skeletons?
    return (
      <div className="w-full h-[calc(100vh-64px)] flex items-center justify-center">
        <Spinner variant="secondary" />
      </div>
    )
  }
  const classNameForErrorLevel = (error: ProductErrorLevel) => {
    switch (error) {
      case ProductErrorLevel.high:
        return "bg-error"
      case ProductErrorLevel.medium:
        return "bg-warning"
      case ProductErrorLevel.low:
        return "bg-info"
    }
  }


  return (
    <div className="pb-5xlarge">
      <BackButton
        path="/a/products"
        label="Back to Products"
        className="mb-xsmall"
      />
      <a href={`https://boardsource.xyz/products/${product.handle}`} target="_blank">view on website</a>
      <div className="grid grid-cols-12 gap-x-base ">
        <div className="col-span-8 flex flex-col gap-y-xsmall">
          <DataSection product={product} />

          <GeneralSection product={product} />
          <KitConfigSection product={product as ExtendedProduct} />
          <VariantsSection product={product} />
          <AttributesSection product={product} />
          <RawSection product={product} />
        </div>
        <div className="flex flex-col col-span-4 gap-y-xsmall relative">
          <ThumbnailSection product={product} />
          <MediaSection product={product} />
          {/* <div className="flex flex-col">
            <h3>List Product</h3>
            <button className="bg-red-200" onClick={listOnEtsy}>Etsy</button>
          </div> */}
          <div className="fixed  col-span-4 bottom-2 w-[22%]">
            {productErrors.length !== 0 ? (
              <ol className="">
                {productErrors.map((error, i) => (
                  <>
                    <li className="text-left border-b-2 border-black text-s	" key={i}>
                      <span className={`${classNameForErrorLevel(error.level)} rounded-full tooltip`} data-tip={error.level}>{i + 1} :</span> <span className="tooltip" data-tip={error.description}> {error.title}</span>
                    </li>
                  </>
                ))}</ol>
            ) : (
              <p>
                No errors found
              </p>
            )}
          </div>
        </div>

      </div>
    </div>
  )
}

export default Edit
