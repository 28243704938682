import Switch from "../../../../../components/atoms/switch"
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../../components/fundamentals/button";
import { CreatePreOrderInput, PreOrder } from "../../../../../types/shared";
import InputField from "../../../../../components/molecules/input";
import { NextSelect } from "../../../../../components/molecules/select/next-select";
import DatePicker from "../../../../../components/atoms/date-picker/date-picker";
import TimePicker from "../../../../../components/atoms/date-picker/time-picker";


type Props = {
    preOrder?: PreOrder
    onSubmit: (preOrder: PreOrder | CreatePreOrderInput) => void
}
type CreatePreOrderInputForm = {
    title: string
    canBuyRes: boolean
    canCompleteRes: boolean

    finished: boolean
    shipping: boolean

    estimatedShippingDate: string | null
    metadata: Record<string, unknown> | null
}



const PreOrderForm = ({ preOrder, onSubmit: propsOnSubmit }: Props) => {
    const { register, handleSubmit, watch, reset, formState: { errors, isDirty }, control } =
        useForm<CreatePreOrderInputForm>({ defaultValues: getDefaultValues(preOrder) });
    const onSubmit = (data) => {
        let valuePreOrder: CreatePreOrderInput = {
            title: data.title,

            canBuyRes: data.canBuyRes,
            canCompleteRes: data.canCompleteRes,
            finished: data.finished,
            shipping: data.shipping,

            estimatedShippingDate: data.startTime ? `${data.startTime}` : null,
            metadata: null,
        }
        propsOnSubmit(valuePreOrder)
    }

    useEffect(() => {
        reset(getDefaultValues(preOrder))
    }, [preOrder])


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h3>canBuyRes:</h3>
            <Controller
                control={control}
                name={"canBuyRes"}
                render={({ field: { value, onChange } }) => {
                    return <Switch checked={value} onCheckedChange={onChange} />
                }}
            />
            <h3>canCompleteRes:</h3>
            <Controller
                control={control}
                name={"canCompleteRes"}
                render={({ field: { value, onChange } }) => {
                    return <Switch checked={value} onCheckedChange={onChange} />
                }}
            />
            <h3>finished:</h3>
            <Controller
                control={control}
                name={"finished"}
                render={({ field: { value, onChange } }) => {
                    return <Switch checked={value} onCheckedChange={onChange} />
                }}
            />
            <h3>shipping:</h3>
            <Controller
                control={control}
                name={"shipping"}
                render={({ field: { value, onChange } }) => {
                    return <Switch checked={value} onCheckedChange={onChange} />
                }}
            />
            <h3>Title:</h3>
            <Controller
                control={control}
                name={"title"}
                render={({ field: { value, onChange } }) => {
                    return <InputField
                        placeholder="title"
                        onChange={onChange}
                        value={value}
                    />
                }}
            />

            {errors.title && <span>This field is required</span>}



            <Controller
                name="estimatedShippingDate"
                control={control}
                render={({ field: { value, onChange } }) => {
                    const ensuredDate = value || new Date()
                    return (
                        <>
                            <DatePicker
                                //@ts-ignore
                                date={ensuredDate}
                                label="End Date"
                                onSubmitDate={onChange}
                            />
                            <TimePicker
                                //@ts-ignore
                                date={ensuredDate}
                                label="End Time"
                                onSubmitDate={onChange}
                            />
                        </>
                    )
                }}
            />


            <Button
                className="mt-2"
                size="small"
                variant="primary"
                type="submit"
                disabled={!isDirty}
            >
                Save
            </Button>
        </form>
    )
}
const getDefaultValues = (preOrder: PreOrder | undefined) => {
    let emptyPreOrder = {
        title: "",
        active: false,
        estimatedShippingDate: null,
        metadata: null,
    }
    if (preOrder) {
        for (const [key, value] of Object.entries(preOrder)) {
            if (value && value !== undefined) {

                emptyPreOrder[key] = value

            }

        }

    }
    return emptyPreOrder
}


export default PreOrderForm
