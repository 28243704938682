import React, { useEffect, useState } from "react"

import Table from "../../molecules/table"
import Medusa from "../../../services/api"
import useToggleState from "../../../hooks/use-toggle-state"
import TagModal from "./tag-modal"
import { ProductTag } from "@medusajs/medusa"




const TagsTable: React.FC = () => {
    const [tags, setTags] = useState<any>(),
        [editTag, setEditTag] = useState("")
    const { state, toggle, close } = useToggleState()
    useEffect(() => {
        if (!tags) {
            Medusa.products.listTagsByUsage().then(res => {
                setTags(res.data.tags)
            })
        }
    })


    const onRowClick = (tag: ProductTag) => {
        setEditTag(tag.id)
        toggle()
    }

    const renderRow = (row) => {
        return (
            <Table.Row
                color={"inherit"}
                onClick={() => onRowClick(row)}
                className="hover:bg-stone-200"
            >

                <Table.Cell>
                    {row.value}
                </Table.Cell>
                <Table.Cell>
                    {row.usage_count}
                </Table.Cell>
                <Table.Cell>
                    {row.id}
                </Table.Cell>

            </Table.Row>
        )
    }
    return (

        <>
            <Table
                isLoading={!tags}
            >

                <>
                    <Table.Head>

                        <Table.HeadRow >

                            <Table.HeadCell
                                className="min-w-[100px]"
                            >
                                Value
                            </Table.HeadCell>
                            <Table.HeadCell
                                className="min-w-[100px]"
                            >
                                Usage Count

                            </Table.HeadCell>

                            <Table.HeadCell
                                className="min-w-[100px]"
                            >
                                Id

                            </Table.HeadCell>

                        </Table.HeadRow>

                    </Table.Head>
                    {tags && (
                        <Table.Body >
                            {tags.map((row) => {
                                return renderRow(row)
                            })}
                        </Table.Body>
                    )}

                </>

            </Table>
            <TagModal onClose={close} open={state} tag={editTag} />

        </>
    )
}

export default TagsTable
