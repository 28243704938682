import { Link, navigate } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"

import Medusa from "../../../services/api"

import { KitConfig } from "../../../types/shared"
import SimpleTable from "../../../domain/dashboard/table"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"
import { KitConfigView } from "../../../domain/products/edit/sections/kitConfig/KitConfigView"
import KitConfigModal from "./kitconfig-modal"


const KitConfigIndex = () => {
    const notification = useNotification()

    const [showKitConfig, setShowKitConfig] = useState(false),
        [selectedConfig, setSelectedConfig] = useState<KitConfig | undefined>()
    const getKitconfigs = async () => {
        const res = await Medusa.customRoutes.getKitConfigs()
        setKitconfigs(res.data)
        console.log(res.data)

    }
    const handleEditKitconfig = async (data) => {
        try {
            if (selectedConfig) {

                await Medusa.customRoutes.updateKitConfig(selectedConfig.id, data)
                setShowKitConfig(false)
                setSelectedConfig(undefined)
                notification("Success", "Successfully created KitConfig", "success")
                getKitconfigs()

            } else {
                console.log("no product selected")
            }

        } catch (error) {
            notification("Error", getErrorMessage(error), "error")
            console.log(error)
        }
    }


    const [kitconfigs, setKitconfigs] = useState<KitConfig[] | undefined>()
    useEffect(() => {

        if (kitconfigs === undefined) {
            getKitconfigs()
        }
    }, [kitconfigs])

    return (
        <div className="flex flex-col grow h-full">
            <div className="w-full flex flex-col grow">

                {kitconfigs !== undefined ? (
                    <div className="w-full">
                        {kitconfigs.map((kitconfig, index) => (

                            <button onClick={() => {
                                setShowKitConfig(true)
                                setSelectedConfig(kitconfig)
                            }}

                                className="mt-base bg-grey-5 rounded-rounded px-base py-xsmall"

                                key={kitconfig.id}>
                                <KitConfigView kitConfig={kitconfig} index={index} />

                            </button>

                        ))}
                    </div>) :
                    ("loading")
                }


            </div>
            {showKitConfig && selectedConfig !== undefined && (
                <KitConfigModal
                    kitconfig={selectedConfig}
                    onClose={() => setShowKitConfig(!showKitConfig)}
                    handelSubmit={handleEditKitconfig} open={showKitConfig} />
            )}

        </div>
    )
}

export default KitConfigIndex;