import React from "react"
import { PreOrder } from "../../../../../types/shared"

const PreOrderView = ({ onClick, preOrder }: { preOrder: PreOrder, onClick: (preOrder: PreOrder) => void }) => {
    const returnLevelClass = (value: boolean) => {
        return value ? "badge-success" : "badge-error "
    }

    return (
        <div className="flex flex-col card bg-base-300 rounded-box mb-2" onClick={() => onClick(preOrder)}>
            <div className="flex pt-2 flex justify-around">
                <div className={`badge whitespace-nowrap ${!preOrder.finished ? "badge-success" : "badge-error"}`}>
                    {!preOrder.finished ? "Active" : "Not-Active"}
                </div>
                <div className="">
                    title: {preOrder.title}
                </div>
            </div>
            <div className="flex pt-2 flex justify-around">
                id: {preOrder.id}
            </div>

            <div className="divider"></div>
            <div className="flex pb-2 flex-wrap flex justify-around">

                <div className={`badge whitespace-nowrap mb-1 ${returnLevelClass(preOrder.canBuyRes)}`}>
                    Can Buy Res
                </div>
                <div className={`badge whitespace-nowrap mb-1 ${returnLevelClass(preOrder.canCompleteRes)}`}>
                    Can Complete Res
                </div>

                <div className={`badge whitespace-nowrap mb-1 ${returnLevelClass(preOrder.finished)}`}>
                    Finished
                </div>
                <div className={`badge whitespace-nowrap mb-1 ${returnLevelClass(preOrder.shipping)}`}>
                    Shipping
                </div>

                <div className="tooltip tooltip-info" data-tip={preOrder.estimatedShippingDate}>
                    <div className={`badge whitespace-nowrap mb-1 badge-outline ${preOrder.estimatedShippingDate === null ? "badge-error" : "badge-info"}`}>
                        Estimated Shipping Date: {preOrder.estimatedShippingDate ? "Hover" : "not-set"}
                    </div>
                </div>

            </div>

        </div>
    )
}

export default PreOrderView
