import React, { useEffect, useState } from "react"

import Button from "../../fundamentals/button"
import Modal from "../../molecules/modal"
import { CreatePreBuiltKitConfigInput, KitConfig, KitConfigProduct, PreBuiltKitConfig } from "../../../types/shared"
import Switch from "../../atoms/switch";
import { Controller, useForm } from "react-hook-form"
import products from "../../../domain/products"
import InputField from "../../molecules/input"
import { NextSelect } from "../../molecules/select/next-select";
import SSelect from "../../molecules/select";
import Medusa from "../../../services/api"


type Props = {
    open: boolean
    onClose: () => void
    handelSubmit: (kitconfig: CreatePreBuiltKitConfigInputFourm) => void
}

export type CreatePreBuiltKitConfigInputFourm = { title: string, description: string, oldData: string }


const PrebuiltKitConfigImportModal = ({ open, onClose, handelSubmit }: Props) => {


    const { register, handleSubmit, watch, reset, formState: { errors, isDirty }, control } =

        //@ts-ignore
        useForm<CreatePreBuiltKitConfigInputFourm>({ defaultValues: getDefaultValues() });

    const onSubmit = (data) => {
        let kitconfig = {
            title: data.title,
            description: data.description,
            oldData: data.oldData,
        }
        console.log(kitconfig)
        handelSubmit({ ...kitconfig })

    }



    return (
        <Modal open={open} handleClose={onClose} isLargeModal>
            <Modal.Body>
                <Modal.Header handleClose={onClose}>
                    <h1 className="inter-xlarge-semibold m-0">
                        Pre-built Kit Config Import from old data
                    </h1>
                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <Modal.Content>


                        <p className="inter-small-semibold text-grey-50">Title</p>

                        <Controller
                            control={control}
                            name={`title`}
                            render={({ field: { value, onChange } }) => {
                                return <InputField
                                    placeholder="Ble Corne Config"
                                    onChange={onChange}
                                    value={value}
                                />
                            }}
                        />
                        <p className="inter-small-semibold text-grey-50">Description</p>

                        <Controller
                            control={control}
                            name={`description`}
                            render={({ field: { value, onChange } }) => {
                                return <InputField
                                    placeholder="Everything pre selected for BLE MX Corne kit"
                                    onChange={onChange}
                                    value={value}
                                />
                            }}
                        />

                        <p className="inter-small-semibold text-grey-50">Old Json data (json) array</p>

                        <Controller
                            control={control}
                            name={`oldData`}
                            render={({ field: { value, onChange } }) => {
                                return <InputField
                                    placeholder="Raw Product > metadata > kitConfig array"
                                    onChange={onChange}
                                    value={value}
                                />
                            }}
                        />



                    </Modal.Content>
                    <Modal.Footer>
                        <div className="flex gap-x-2 justify-end w-full">
                            <Button
                                size="small"
                                variant="secondary"
                                type="button"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="secondary"
                                type="submit"

                            >
                                Save
                            </Button>

                        </div>
                    </Modal.Footer>
                </form>

            </Modal.Body>
        </Modal>
    )
}



const getDefaultValues = () => {
    let emptyPreBuiltKitConfig: CreatePreBuiltKitConfigInput = {
        title: "",
        description: "",
        kitConfig: []
    }


    return emptyPreBuiltKitConfig
}


export default PrebuiltKitConfigImportModal
