
import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import Switch from "../../../../../components/atoms/switch"
import IndeterminateCheckbox from "../../../../../components/molecules/indeterminate-checkbox"
import { NextSelect } from "../../../../../components/molecules/select/next-select"
import { NestedForm } from "../../../../../utils/nested-form"
import InputField from "../../../../../components/molecules/input"
import SortableMultiSelect from "../../../../../components/atoms/sortable-multi-select"
import { PreBuiltKitConfig } from "../../../../../types/shared"
import Medusa from "../../../../../services/api"
import useNotification from "../../../../../hooks/use-notification"

export type PreBuiltKitConfigsType = {
    preBuiltKitConfigs: { value: string; label: string }[]
}

type PreBuiltKitConfigsFormProps = {
    form: NestedForm<PreBuiltKitConfigsType>
    productId: string
}

const PreBuiltKitConfigsForm = ({ form, productId }: PreBuiltKitConfigsFormProps) => {
    const notification = useNotification()

    const {
        register,
        path,
        control,
        formState: { errors },
    } = form
    const [prebuiltkitconfigs, setPrebuiltkitconfigs] = useState<PreBuiltKitConfig[] | undefined>()

    const kitconfigOptions = prebuiltkitconfigs === undefined ? [] : prebuiltkitconfigs.map((prebuiltkitconfig) => ({
        label: prebuiltkitconfig.title,
        value: prebuiltkitconfig.id
    }))


    useEffect(() => {
        const getKitconfigs = async () => {
            const res = await Medusa.customRoutes.getPrebuiltkitconfigs()
            setPrebuiltkitconfigs(res.data)

        }
        if (prebuiltkitconfigs === undefined) {
            getKitconfigs()
        }
        //@ts-ignore

        // reset(getDefaultValues(kitconfig))
    }, [prebuiltkitconfigs])

    const convertOldToNew = async () => {
        try {
            const res = await Medusa.customRoutes.importPrebuiltkitconfigs({ productId })
            notification("Success", "Successfully imported pre-built kit configs", "success")

        } catch (error: any) {
            notification("Error", error.message, "error")

        }

    }

    return (
        <div>
            <div className="flex flex-col">
                <h2 className="inter-base-semibold">Pre-built Kit Configs</h2>
                <button className="btn btn-error mb-4 mt-4" type="button" onClick={convertOldToNew}>
                    convert old to new
                </button>
                <Controller
                    control={control}
                    name={path("preBuiltKitConfigs")}
                    render={({ field }) => {
                        return (
                            <SortableMultiSelect

                                options={kitconfigOptions}
                                // isMultiSelect
                                //@ts-ignore
                                isSearchable
                                isClearable
                                value={field.value}
                                onChange={field.onChange}

                            />
                        )
                    }}
                />


            </div>

        </div>
    )
}

export default PreBuiltKitConfigsForm

