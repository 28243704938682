import React from "react"
type SimpleTableProps = {
    heads: string[]
    title: string
    className?: string
    children?: any
    noMaxHeight?: boolean
}
const SimpleTable = ({ heads, children, title, className, noMaxHeight }: SimpleTableProps) => {
    return (
        <div className={`card bg-base-100 shadow-xl ${className}`}>
            <div className="card-body">
                <h2 className="card-title">{title}</h2>

                <div className={`overflow-auto ${noMaxHeight ? "" : "max-h-96"}`}>
                    <table className="table table-zebra w-full">
                        {/* head */}
                        <thead>
                            <tr>
                                {heads.map((head, index) => (

                                    <th key={index}>{head}</th>

                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {children}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default SimpleTable