import React from "react"
import { Controller, FieldArrayWithId, useFieldArray } from "react-hook-form"
import { NextCreateableSelect, NextSelect } from "../../../../../components/molecules/select/next-select"
import { NestedForm } from "../../../../../utils/nested-form"
import InputField from "../../../../../components/molecules/input"
import { Option } from "../../../../../types/shared"
import Switch from "../../../../../components/atoms/switch"
import ArrayOfObjectsInput from "./array-of-objects-input"
import { useAdminProducts } from "medusa-react"






export type KitConfigProducts = {

  product_id: Option
  qtyNeeded: Number
  preSelected: boolean
  subTitle: string
}

export type KitConfigMetadata = {

  title: string
  mustSelect: boolean
  selectOne: boolean
  products: KitConfigProducts[]
}

export type KitConfigFormFormType = KitConfigMetadata[]

type Props = {
  form: NestedForm<KitConfigFormFormType>
  // kitConfig: KitConfigMetadata[]
  onCreatekitConfig: (optionId: string, value: string) => void
}

const KitConfigForm = ({ form, onCreatekitConfig }: Props) => {
  const { register, control, path } = form
  const { fields, remove, append } = useFieldArray({
    name: path(),
    control: form.control,
    shouldUnregister: true,
  })

  const { products, isLoading, isRefetching, count } = useAdminProducts({ limit: 500 })


  const productOptions = products === undefined ? [] : products.map((product) => ({
    label: product.title ? product.title : "",
    value: product.id ? product.id : "",
  }))
  const handleAddAnOption = (append: (input: KitConfigMetadata) => void, data?: KitConfigMetadata) => {
    if (data) {
      append(data)
    } else {
      append({ title: "", mustSelect: false, selectOne: false, products: [] })
    }

  }
  const handleAddAnProduct = (append: (input: KitConfigProducts) => void) => {
    append({ product_id: { value: "", label: "" }, subTitle: "", preSelected: false, qtyNeeded: 1 })
  }


  return (
    <div className="gap-large pb-2xsmall">
      <h2 className="inter-large-semibold mb-base">kit Config options</h2>
      <div className="flex flex-col gap-y-small">
        <div className="flex flex-col gap-y-xsmall">

          <ArrayOfObjectsInput
            addBtnTxt="Add Kit Config Option"
            form={form}
            onCreateObject={handleAddAnOption}
            saveOption
            render={(field: FieldArrayWithId, index: number, append) => (<>
              <p className="inter-small-semibold text-grey-50">kit Config title</p>

              <Controller
                control={control}
                name={path(`${index}.title`)}
                render={({ field: { value, onChange } }) => {
                  return <InputField
                    key={field.id}
                    placeholder="title"
                    onChange={onChange}
                    value={value}
                  />
                }}
              />
              <div className="flex">
                <div className="flex items-center justify-between mb-2xsmall">
                  <h2 className="inter-base-semibold">Must Select</h2>
                  <Controller
                    control={control}
                    name={path(`${index}.mustSelect`)}
                    render={({ field: { value, onChange } }) => {
                      return <Switch checked={value} onCheckedChange={onChange} />
                    }}
                  />
                </div>
                <div className="flex items-center justify-between mb-2xsmall">
                  <h2 className="inter-base-semibold">Select One</h2>
                  <Controller
                    control={control}
                    name={path(`${index}.selectOne`)}
                    render={({ field: { value, onChange } }) => {
                      return <Switch checked={value} onCheckedChange={onChange} />
                    }}
                  />
                </div>
              </div>
              <div
                className=" gap-x-xsmall bg-grey-30 rounded-rounded m-2xsmall"
              >
                <h2 className="inter-base-semibold">
                  Products
                </h2>
                <ArrayOfObjectsInput
                  className="m-small"
                  addBtnTxt="Add Product"
                  form={form}
                  onCreateObject={handleAddAnProduct}
                  nested={`${index}.products`}
                  render={(fieldNested: FieldArrayWithId, indexNested: number) => (<div
                    className="">
                    <p className="inter-small-semibold text-grey-50">product Subtitle</p>
                    <Controller
                      control={control}
                      name={path(`${index}.products.${indexNested}.subTitle`)}
                      render={({ field: { value, onChange } }) => {
                        return <InputField
                          key={fieldNested.id}
                          placeholder="Subtitle"
                          onChange={onChange}
                          value={value}
                        />
                      }}
                    />

                    <Controller
                      control={control}
                      name={path(`${index}.products.${indexNested}.qtyNeeded`)}
                      render={({ field: { value, onChange } }) => {
                        return <InputField
                          label="qty needed"
                          placeholder="10..."
                          type="number"
                          onChange={onChange}
                          value={`${value}`}
                        />
                      }}
                    />


                    <div className="flex">

                      <div className="flex items-center justify-between mb-2xsmall">
                        <h2 className="inter-base-semibold">Pre-selected</h2>
                        <Controller
                          control={control}
                          name={path(`${index}.products.${indexNested}.preSelected`)}
                          render={({ field: { value, onChange } }) => {
                            return <Switch checked={value} onCheckedChange={onChange} />
                          }}
                        />
                      </div>
                    </div>
                    <Controller
                      control={control}
                      name={path(`${index}.products.${indexNested}.product_id`)}
                      render={({ field }) => {
                        return (
                          <NextSelect
                            label="Product option"
                            placeholder="Choose a product"
                            //@ts-ignore
                            options={productOptions}
                            isSearchable
                            isClearable
                            {...field}
                          />
                        )
                      }}
                    />
                  </div>)} />
              </div>


            </>)} />
        </div>
      </div>

    </div>
  )
}

export default KitConfigForm
