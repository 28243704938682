import React, { useState } from "react"
import { Controller, FieldArrayWithId, useFieldArray, UseFieldArrayAppend, UseFormReturn } from "react-hook-form"
import { NextCreateableSelect, NextSelect } from "../../../../../components/molecules/select/next-select"
import { NestedForm } from "../../../../../utils/nested-form"
import InputField from "../../../../../components/molecules/input"
import TrashIcon from "../../../../../components/fundamentals/icons/trash-icon"
import PlusIcon from "../../../../../components/fundamentals/icons/plus-icon"
import FormValidator from "../../../../../utils/form-validator"
import Button from "../../../../../components/fundamentals/button"
import Switch from "../../../../../components/atoms/switch"
import { useAdminProducts } from "medusa-react"
import Input from "../../../../../components/molecules/input"
import { KitConfigMetadata } from "."



const localStorageKey = "autofill_state"

type AutofillStateType = Record<string, { value: KitConfigMetadata, title: string }[]>

type Props = {
    className?: string
    addBtnTxt: string
    form: NestedForm<any>
    // kitConfig: KitConfigMetadata[]
    onCreateObject: (append: any, data?: KitConfigMetadata) => void
    render: (field: FieldArrayWithId, index: number, append?: UseFieldArrayAppend<{
        __nested__: any;
    }, "__nested__" | `__nested__.${string}`>) => any
    nested?: string
    saveOption?: boolean
}

const ArrayOfObjectsInput = ({ form, onCreateObject, render, addBtnTxt, className, nested, saveOption }: Props) => {
    const { register, control, path, watch } = form,
        { fields, remove, append } = useFieldArray({
            name: nested ? `${path()}.${nested}` : path(),
            control: form.control,
            shouldUnregister: true,
        }),
        [saveTitle, setSaveTitle] = useState("")
    const saveButton = (index: number) => {
        const allValues: KitConfigMetadata[] = watch(nested ? `${path()}.${nested}` : path())
        if (allValues && Array.isArray(allValues) && allValues.length - 1 >= index) {
            const currentValue = allValues[index]
            const currentPath = nested ? `${path()}.${nested}` : path()
            const localState = localStorage.getItem(localStorageKey)
            if (localState) {
                const autofill_state: AutofillStateType = JSON.parse(localState)
                if (autofill_state[currentPath]) {
                    const is_update = autofill_state[currentPath].find(value => value.title === saveTitle)
                    if (is_update) {
                        autofill_state[currentPath].map(value => value.title === saveTitle ? { value: currentValue, title: saveTitle } : value)
                    } else {
                        autofill_state[currentPath].push({ value: currentValue, title: saveTitle })
                    }

                } else {
                    autofill_state[currentPath] = [{ value: currentValue, title: saveTitle }]
                }
                localStorage.setItem(localStorageKey, JSON.stringify(autofill_state))
            } else {
                const autofill_state: AutofillStateType = { [currentPath]: [{ value: currentValue, title: saveTitle }] }
                localStorage.setItem(localStorageKey, JSON.stringify(autofill_state))
            }
        }
    }
    const returnAutoFills = () => {
        const localState = localStorage.getItem(localStorageKey)
        if (localState) {
            const currentPath = nested ? `${path()}.${nested}` : path()
            const autofill_state: AutofillStateType = JSON.parse(localState)
            if (autofill_state[currentPath]) {
                return autofill_state[currentPath]
            }
        }
        return []

    }
    return (
        <div className={`gap-large pb-2xsmall ${className}`}>

            <div className="flex flex-col gap-y-small">
                <div className="flex flex-col gap-y-xsmall">
                    {fields.map((field, index) => {
                        return (
                            <div
                                className=" gap-x-xsmall bg-grey-5 rounded-rounded"
                                key={field.id}
                            >
                                {render(field, index, append)}
                                <div className="flex justify-between	">
                                    <Button
                                        variant="secondary"
                                        className="px-2.5 py-2.5 max-h-[40px]"
                                        type="button"
                                        onClick={() => remove(index)}
                                    >
                                        <TrashIcon className="text-grey-40" size="20" />
                                    </Button>
                                    {saveOption &&
                                        (
                                            <div className="flex">
                                                <Input
                                                    onChange={(e) => setSaveTitle(e.target.value)}
                                                    label="Save title"
                                                />
                                                <Button
                                                    variant="secondary"
                                                    className=" h-10 mt-base"
                                                    type="button"
                                                    onClick={() => saveButton(index)}
                                                >
                                                    Save auto fill
                                                </Button>

                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <Button
                variant="primary"
                className="w-full h-10 mt-base"
                type="button"
                onClick={() => {
                    onCreateObject(append)
                }}
            >
                <PlusIcon size="20" /> {addBtnTxt}
            </Button>
            {saveOption &&
                (<div className="flex flex-col">
                    <p>Saved Auto fill:</p>
                    <div className="flex flex-row flex-wrap">
                        {returnAutoFills().map((autoFill, index) => (
                            <Button
                                variant="secondary"
                                className="w-1/4 h-10 mt-base tooltip  tooltip-lef"
                                type="button"
                                data-tip={JSON.stringify(autoFill.value, null, 2)}
                                onClick={() => {
                                    onCreateObject(append, autoFill.value)
                                }}
                            >
                                {autoFill.title}
                            </Button>
                        ))}


                    </div></div>
                )
            }

        </div>
    )
}

export default ArrayOfObjectsInput
