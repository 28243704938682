import React from "react"
import { Controller } from "react-hook-form"
import Switch from "../../../../../components/atoms/switch"
import IndeterminateCheckbox from "../../../../../components/molecules/indeterminate-checkbox"
import { NextSelect } from "../../../../../components/molecules/select/next-select"
import { NestedForm } from "../../../../../utils/nested-form"

export type ReservationProductType = {
    reservationProduct: boolean

}

type ReservationProductFormProps = {
    form: NestedForm<ReservationProductType>
}

/**
 * Re-usable nested form used to submit customs information for products and their variants.
 * @example
 * <HiddenForm form={nestedForm(form, "hidden")} />
 */
const ReservationProductForm = ({ form }: ReservationProductFormProps) => {
    const {
        register,
        path,
        control,
        formState: { errors },
    } = form


    return (
        <div>
            <div className="flex items-center justify-between mb-2xsmall">
                <h2 className="inter-base-semibold">Reservation Product</h2>
                <Controller
                    control={control}
                    name={path("reservationProduct")}
                    render={({ field: { value, onChange } }) => {
                        return <Switch checked={value} onCheckedChange={onChange} />
                    }}
                />
            </div>
            <p className="inter-base-regular text-grey-50">
                When checked this product is a reservation Product and needs to be linked to the physical Product
            </p>
        </div>
    )
}

export default ReservationProductForm
