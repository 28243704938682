import React from "react"
import { Controller } from "react-hook-form"
import Switch from "../../../../../components/atoms/switch"
import IndeterminateCheckbox from "../../../../../components/molecules/indeterminate-checkbox"
import { NextSelect } from "../../../../../components/molecules/select/next-select"
import { NestedForm } from "../../../../../utils/nested-form"
import InputField from "../../../../../components/molecules/input"
import FormValidator from "../../../../../utils/form-validator"



export type SelfAsConfigFormType = {
    selfAsConfig: boolean
    qtyNeeded: Number
    preSelected: boolean
    subTitle: string
    title: string
    mustSelect: boolean
    selectOne: boolean
}

type SelfAsConfigFormProps = {
    form: NestedForm<SelfAsConfigFormType>
}

/**
 * Re-usable nested form used to submit customs information for products and their variants.
 * @example
 * <HiddenForm form={nestedForm(form, "hidden")} />
 */
const SelfAsConfigForm = ({ form }: SelfAsConfigFormProps) => {
    const {
        register,
        path,
        control,
        formState: { errors },
    } = form


    return (
        <div>
            <div className="flex items-center justify-between mb-2xsmall">
                <h2 className="inter-base-semibold">Self as Config Option</h2>
                <Controller
                    control={control}
                    name={path("selfAsConfig")}
                    render={({ field: { value, onChange } }) => {
                        return <Switch checked={value} onCheckedChange={onChange} />
                    }}
                />
            </div>
            <p className="inter-base-regular text-grey-50">
                When checked the product will be shown as another option in the kit configurator instead of using variant render
            </p>
            <div className="flex flex-col">
                <InputField
                    label="Section Title"
                    placeholder="title"
                    {...register(path("title"), {
                        pattern: FormValidator.whiteSpaceRule("title"),
                    })}
                    errors={errors}
                />

                <div className="flex">
                    <div className="flex items-center justify-between mb-2xsmall">
                        <h2 className="inter-base-semibold">Pre-selected</h2>
                        <Controller
                            control={control}
                            name={path(`preSelected`)}
                            render={({ field: { value, onChange } }) => {
                                return <Switch checked={value} onCheckedChange={onChange} />
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-between mb-2xsmall">
                        <h2 className="inter-base-semibold">Must Select Section</h2>
                        <Controller
                            control={control}
                            name={path(`mustSelect`)}
                            render={({ field: { value, onChange } }) => {
                                return <Switch checked={value} onCheckedChange={onChange} />
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-between mb-2xsmall">
                        <h2 className="inter-base-semibold">Select One Section</h2>
                        <Controller
                            control={control}
                            name={path(`selectOne`)}
                            render={({ field: { value, onChange } }) => {
                                return <Switch checked={value} onCheckedChange={onChange} />
                            }}
                        />
                    </div>

                </div>
                <InputField
                    label="Product Sub-title"
                    placeholder="subTitle"
                    {...register(path("subTitle"), {
                        pattern: FormValidator.whiteSpaceRule("subTitle"),
                    })}
                    errors={errors}
                />
                <InputField
                    label="qty needed"
                    placeholder="10..."
                    type="number"
                    {...register(path("qtyNeeded"), {
                        pattern: FormValidator.whiteSpaceRule("qtyNeeded"),
                    })}
                    errors={errors}
                />



            </div>
        </div>
    )
}

export default SelfAsConfigForm
