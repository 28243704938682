import { Link, navigate } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"

import PlusIcon from "../../../components/fundamentals/icons/plus-icon"
import BodyCard from "../../../components/organisms/body-card"
import TableViewHeader from "../../../components/organisms/custom-table-header"

import Medusa from "../../../services/api"

import { VIEWS } from ".."
import PublishIcon from "../../../components/fundamentals/icons/publish-icon"
import { Order } from "@medusajs/medusa"
import SimpleTable from "../../dashboard/table"
import toast from "react-hot-toast"
type TodoResponce = {
  openOrders: Order[]
  ordersToCheck: Order[]
  ordersToSolder: Order[]
  customerService: Order[]
}
type Data = {
  count: number
  amountSold: number
  day: number
}
const printInView = async (orders) => {

  const ordersInView = orders.filter(order => order.status !== "canceled").map(order => order.id)
  if (window) {

    try {
      const res = await Medusa.customRoutes.shipList(ordersInView)
      if (window) {
        window.open(res.data.url, '_blank');
      }
      return res.data.url

    } catch (error) {
      console.log(error)
      throw error
    }
  }

}
const Tables = ({ openOrders,
  ordersToCheck,
  ordersToSolder,
  customerService, }: TodoResponce) => {

  useEffect(() => {
    console.log("YOU", {
      openOrders,
      ordersToCheck,
      ordersToSolder,
      customerService,
    })
  })

  return (
    <div className="flex flex-col">

      <SimpleTable
        title={"Open Orders"}
        className="mb-4"

        heads={["#", "Date", "Customer", "Fulfillment", "Payment status", "total"]}>
        {openOrders.map((order) => (

          <tr key={order.id}>
            <th>
              <Link to={`/a/orders/${order.id}`} >
                #  {order.display_id}
              </Link>
            </th>
            <td>
              {order.created_at}
            </td>
            <td>
              {order.shipping_address.first_name} {order.shipping_address.last_name}
            </td>
            <td>{order.fulfillment_status}</td>
            <td>{order.payment_status}</td>
            <td>${(order.total / 100).toLocaleString()}</td>
          </tr>

        ))}
      </SimpleTable>
      <SimpleTable
        title={"Customer Service"}
        className="mb-4"

        heads={["#", "Date", "Customer", "Fulfillment", "Payment status", "total"]}>
        {customerService.map((order) => (

          <tr key={order.id}>
            <th>
              <Link to={`/a/orders/${order.id}`} >
                # {order.display_id}
              </Link>
            </th>
            <td>
              {order.created_at}
            </td>
            <td>
              {order.shipping_address.first_name} {order.shipping_address.last_name}
            </td>
            <td>{order.fulfillment_status}</td>
            <td>{order.payment_status}</td>
            <td>${(order.total / 100).toLocaleString()}</td>
          </tr>

        ))}
      </SimpleTable>
      <SimpleTable
        title={"Orders To Check"}
        className="mb-4"

        heads={["#", "Date", "Customer", "Fulfillment", "Payment status", "total"]}>
        {ordersToCheck.map((order) => (

          <tr key={order.id}>
            <th>
              <Link to={`/a/orders/${order.id}`}>
                # {order.display_id}
              </Link>
            </th>
            <td>
              {order.created_at}
            </td>
            <td>
              {order.shipping_address.first_name} {order.shipping_address.last_name}
            </td>
            <td>{order.fulfillment_status}</td>
            <td>{order.payment_status}</td>
            <td>${(order.total / 100).toLocaleString()}</td>
          </tr>

        ))}
      </SimpleTable>

      <SimpleTable
        title={"Soldering service"}
        className="mb-4"

        heads={["#", "Date", "Customer", "Fulfillment", "Payment status", "total"]}>
        {ordersToSolder.map((order) => (

          <tr key={order.id}>
            <th>
              <Link to={`/a/orders/${order.id}`}>
                # {order.display_id}
              </Link>
            </th>
            <td>
              {order.created_at}
            </td>
            <td>
              {order.shipping_address.first_name} {order.shipping_address.last_name}
            </td>
            <td>{order.fulfillment_status}</td>
            <td>{order.payment_status}</td>
            <td>${(order.total / 100).toLocaleString()}</td>
          </tr>

        ))}
      </SimpleTable>

    </div>

  )
}

const TodoOrderIndex = () => {
  const view = "todos"
  const [todos, setTodos] = useState<TodoResponce | undefined>(),
    [data, setData] = useState<Data>()
  useEffect(() => {
    const getTodos = async () => {
      const res = await Medusa.customRoutes.getTodos()
      setTodos(res.data)
      getData(res.data)

    }
    if (todos === undefined) {
      getTodos()
    }
  }, [todos])
  const print = async () => {
    toast.success('You Clicked it Im working on it')

    const res = await Medusa.customRoutes.getTodos()
    toast.success('Got Orders sending them to the printer')

    toast.promise(
      printInView(res.data.openOrders),
      {
        loading: 'building pdf',
        success: <b>Printing.</b>,
        error: <b>error</b>,
      }
    );

  }

  const actions = useMemo(() => {
    return [
      {
        label: " Ship and print open orders",
        onClick: print,
        icon: <PublishIcon size={20} />,
      },
    ]
  }, [view])
  const getData = (todos: TodoResponce) => {
    const allOpenOrders = [...todos.openOrders, ...todos.ordersToCheck, ...todos.ordersToSolder]
    let data: Data = {
      day: new Date().getDay(),
      count: allOpenOrders.length,
      amountSold: allOpenOrders.reduce((acc, order) => acc + order.total, 0) / 100,
    }
    const storedData = localStorage.getItem("todoSalesData")
    if (storedData) {
      const tempdata = JSON.parse(storedData)
      if (tempdata.day !== data.day) {
        localStorage.setItem("todoSalesData", JSON.stringify(data))
      } else {
        data = tempdata
      }
    } else {
      localStorage.setItem("todoSalesData", JSON.stringify(data))
    }

    setData(data)


  }

  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        <BodyCard
          customHeader={
            <TableViewHeader
              views={VIEWS}
              setActiveView={(v) => {
                if (v === "orders") {
                  navigate(`/a/orders`)
                }
                if (v === "drafts") {
                  navigate(`/a/draft-orders`)
                }
              }}
              activeView={view}
            />
          }
          actionables={actions}
        >
          {data && (
            <div className="stats shadow">
              <div className="stat">
                <div className="stat-title">Amount Sold Today</div>
                <div className="stat-value">${data.amountSold}</div>
              </div>
              <div className="stat">
                <div className="stat-title">Number of Orders</div>
                <div className="stat-value">{data.count}</div>
              </div>
            </div>
          )}
          {todos !== undefined ? (
            <Tables
              {...todos}
            />) :
            ("loading")
          }


        </BodyCard>
      </div >

    </div >
  )
}

export default TodoOrderIndex