import React, { useEffect, useState } from "react"

import Button from "../../fundamentals/button"
import Modal from "../../molecules/modal"
import { CreateKitConfigInput, KitConfig, KitConfigProduct } from "../../../types/shared"
import Switch from "../../atoms/switch";
import { Controller, useForm } from "react-hook-form"
import products from "../../../domain/products"
import InputField from "../../molecules/input"
import { NextSelect } from "../../molecules/select/next-select";
import SSelect from "../../molecules/select";
import Medusa from "../../../services/api"
import SortableMultiSelect from "../../atoms/sortable-multi-select";


type Props = {
    kitconfig?: KitConfig
    open: boolean
    onClose: () => void
    handelSubmit: (kitconfig: CreateKitConfigInput) => void
}

type CreateKitConfigInputFourm = Omit<CreateKitConfigInput, "products"> & { product_id: { value: string; label: string }[] }


const KitConfigModal = ({ kitconfig, open, onClose, handelSubmit }: Props) => {
    const [kitconfigProducts, setKitconfigProducts] = useState<KitConfigProduct[] | undefined>()

    const productOptions = kitconfigProducts === undefined ? [] : kitconfigProducts.map((product) => ({
        label: product.product_name ? product.product_name : "",
        value: product.id ? product.id : "",
    }))
    console.log(productOptions)
    const { register, handleSubmit, watch, reset, formState: { errors, isDirty }, control } =

        //@ts-ignore
        useForm<CreateKitConfigInputFourm>({ defaultValues: getDefaultValues(kitconfig) });

    const onSubmit = (data) => {
        let kitconfig: CreateKitConfigInput = {
            title: data.title,
            mustSelect: data.mustSelect,
            selectOne: data.selectOne,
            topLevelConfig: data.topLevelConfig,
            topLevelConfigTitle: data.topLevelConfigTitle,
            kitConfigProducts: data.product_id.map((product) => product.value),
        }
        console.log(kitconfig)
        handelSubmit({ ...kitconfig })

    }



    useEffect(() => {
        const getKitconfigProducts = async () => {
            const res = await Medusa.customRoutes.getKitConfigProducts()
            setKitconfigProducts(res.data)

        }
        if (kitconfigProducts === undefined) {
            getKitconfigProducts()
        }
        //@ts-ignore

        // reset(getDefaultValues(kitconfig))
    }, [kitconfig, kitconfigProducts])




    return (
        <Modal open={open} handleClose={onClose} isLargeModal>
            <Modal.Body>
                <Modal.Header handleClose={onClose}>
                    <h1 className="inter-xlarge-semibold m-0">
                        Edit General Information
                    </h1>
                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <Modal.Content>


                        <p className="inter-small-semibold text-grey-50">Top Level Config</p>
                        <div className="flex">

                            <Controller
                                control={control}
                                name={`topLevelConfig`}
                                render={({ field: { value, onChange } }) => {
                                    return <Switch checked={value} onCheckedChange={onChange} />
                                }}
                            />

                            <Controller
                                control={control}
                                name={`topLevelConfigTitle`}
                                render={({ field: { value, onChange } }) => {
                                    return <InputField
                                        placeholder="Top level Title (Case Color)"
                                        onChange={onChange}
                                        value={value}
                                    />
                                }}
                            />
                        </div>
                        <p className="inter-small-semibold text-grey-50">kit Config title</p>

                        <Controller
                            control={control}
                            name={`title`}
                            render={({ field: { value, onChange } }) => {
                                return <InputField
                                    placeholder="title"
                                    onChange={onChange}
                                    value={value}
                                />
                            }}
                        />
                        <div className="flex">
                            <div className="flex items-center justify-between mb-2xsmall">
                                <h2 className="inter-base-semibold">Must Select</h2>
                                <Controller
                                    control={control}
                                    name={`mustSelect`}
                                    render={({ field: { value, onChange } }) => {
                                        return <Switch checked={value} onCheckedChange={onChange} />
                                    }}
                                />
                            </div>
                            <div className="flex items-center justify-between mb-2xsmall">
                                <h2 className="inter-base-semibold">Select One</h2>
                                <Controller
                                    control={control}
                                    name={`selectOne`}
                                    render={({ field: { value, onChange } }) => {
                                        return <Switch checked={value} onCheckedChange={onChange} />
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className=" gap-x-xsmall bg-grey-30 rounded-rounded m-2xsmall"
                        >
                            <h2 className="inter-base-semibold">
                                Products
                            </h2>
                            <Controller
                                control={control}
                                name={`product_id`}
                                render={({ field }) => {
                                    return (
                                        <SortableMultiSelect
                                            // label="Product option"
                                            // placeholder="Choose a product"
                                            options={productOptions}
                                            // isMultiSelect
                                            //@ts-ignore
                                            // isSearchable
                                            // isClearable
                                            value={field.value}
                                            onChange={field.onChange}

                                        />

                                    )
                                }}
                            />

                        </div>




                    </Modal.Content>
                    <Modal.Footer>
                        <div className="flex gap-x-2 justify-end w-full">
                            <Button
                                size="small"
                                variant="secondary"
                                type="button"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="secondary"
                                type="submit"

                            >
                                Save
                            </Button>

                        </div>
                    </Modal.Footer>
                </form>

            </Modal.Body>
        </Modal>
    )
}



const getDefaultValues = (kitConfig: KitConfig | undefined) => {
    //@ts-ignore
    let emptykitConfig: CreateKitConfigInputFourm = {
        title: "",
        mustSelect: false,
        selectOne: false,
        topLevelConfig: false,
        topLevelConfigTitle: "",
        product_id: []
    }

    if (kitConfig) {
        for (const [key, value] of Object.entries(kitConfig)) {
            if (value && value !== undefined) {
                if (key === "kitConfigProducts") {
                    //@ts-ignore
                    emptykitConfig.product_id = kitConfig.kitConfigProducts.map((product) => ({ value: product.id, label: product.product_name }))
                } else if (key === "preSelected") {


                }
                else {
                    emptykitConfig[key] = value

                }
            }

        }

    }

    return emptykitConfig
}


export default KitConfigModal
