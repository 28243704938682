import { RouteComponentProps } from "@reach/router"
import React, { useEffect, useState } from "react"
import BackButton from "../../../components/atoms/back-button"
import Spinner from "../../../components/atoms/spinner"
import Button from "../../../components/fundamentals/button"
import Section from "../../../components/organisms/section"
import useToggleState from "../../../hooks/use-toggle-state"
import Medusa from "../../../services/api"
import { CreateTopBarInput, TopBar, TopBarLevel, TopBarPersistence } from "../../../types/shared"
import TopBarView from "./components/topBar"
import TopBarEditModal from "./components/topBarEditModal"
import TopBarNewModal from "./components/topBarNewModal"


const TopBarSettings = (_props: RouteComponentProps) => {

  const [topBars, setTopBars] = useState<TopBar[]>([]),
    [currentTopBar, setCurrentTopBar] = useState<TopBar>(),
    { state: newstate, toggle: newToggle, close: newClose } = useToggleState(),
    { state: editstate, toggle: editToggle, close: editClose } = useToggleState(),
    [haveFetched, setHaveFetched] = useState(false)

  const onClose = () => {
    newClose()
    editClose()
    Medusa.customRoutes.getTopBars().then(res => {
      setTopBars(res.data)
    })
  }
  useEffect(() => {
    if (topBars.length == 0 && !haveFetched) {
      Medusa.customRoutes.getTopBars().then(res => {
        setTopBars(res.data)
        setHaveFetched(true)
      })
    }
  })
  const onTopBarClick = (topBar: TopBar) => {
    setCurrentTopBar(topBar)
    editToggle()
  }
  if (topBars.length > 0) {
    return (
      <div className="pb-xlarge">
        <BackButton
          label="Back to Settings"
          path="/a/settings"
          className="mb-xsmall"
        />
        <div className="grid grid-cols-3 gap-base">
          <div className="col-span-2 flex flex-col gap-y-xsmall ">
            <Section title="Top Bar">
              <div className="flex justify-between	">
                <p className="text-grey-50 inter-base-regular mt-2xsmall">
                  Manage the top bar notifacations.
                </p>
                <Button variant={"primary"} onClick={() => newToggle()}>New Top Bar</Button>
              </div>
            </Section>

            <Section>
              <h3>
                All Top Bars *click to edit
              </h3>

              {topBars.map(topBar => (<TopBarView topBar={topBar} onClick={onTopBarClick} />))}
            </Section>
          </div>
          <div>
            <Section>
              <h3>
                Active TopBars
              </h3>
              {topBars
                .filter(topBar => topBar.active)
                .map(topBar => (<TopBarView topBar={topBar} onClick={onTopBarClick} />))}
            </Section>
          </div>
        </div>
        <TopBarNewModal open={newstate} onClose={onClose} />
        <TopBarEditModal topBar={currentTopBar} onClose={onClose} open={editstate} />
      </div>
    )
  }
  return (
    <div className="pb-xlarge">
      <BackButton
        label="Back to Settings"
        path="/a/settings"
        className="mb-xsmall"
      />
      <div className="grid grid-cols-3 gap-base">
        <div className="col-span-2 flex flex-col gap-y-xsmall ">
          <Section title="Top Bar">
            <div className="flex justify-between	">
              <p className="text-grey-50 inter-base-regular mt-2xsmall">
                Manage the top bar notifacations.
              </p>
              <Button variant={"primary"} onClick={() => newToggle()}>New Top Bar</Button>
            </div>
          </Section>

          <Section>
            <Spinner withText />
          </Section>
        </div>
        <div>
          <Section>
            <Spinner withText />
          </Section>
        </div>
      </div>
      <TopBarNewModal open={newstate} onClose={onClose} />
      <TopBarEditModal topBar={currentTopBar} onClose={onClose} open={editstate} />
    </div>
  )
}

export default TopBarSettings
