import { RouteComponentProps, Router } from "@reach/router"
import React, { useEffect, useState } from "react"
import CurrencyInput from "../../components/molecules/currency-input"
import Select from "../../components/molecules/select"

import BodyCard from "../../components/organisms/body-card"
import Medusa from "../../services/api"
import { DashboardGraphData, DateOptions, GenralDashboardData, TimeDashboardData } from "../../types/shared"
import GenralData from "./genral-data"
import TimeData from "./time-data"
import Hotlinks from "./hotlinks"
import Graphs from "./graphs"
import OrderTrackerView from "./orderTracker"


const Dashboard: React.FC<RouteComponentProps> = () => {
    const [genralDashboardData, setGenralDashboardData] = useState<GenralDashboardData>(),
        [timeDashboardData, setTimeDashboardDatea] = useState<TimeDashboardData>(),
        [lowStockQty, setLowStockQty] = useState(100),
        [timeFrame, setTimeFrame] = useState<DateOptions>(DateOptions.month),
        [salesData, setSalesData] = useState<DashboardGraphData>()
    const fetchData = async () => {
        try {
            const data = await Medusa.customRoutes.getDashBoardData(lowStockQty, timeFrame)
            const salesData = await Medusa.customRoutes.getDashBoardSalesData()
            setSalesData(salesData.data)
            setTimeDashboardDatea(data.data.timeData)
            setGenralDashboardData(data.data.genralData)
        } catch (error) {
            console.log("error", error);
        }
    };

    const dateOptionToString = (dateOption: DateOptions) => {
        switch (dateOption) {
            case DateOptions.day:
                return "Day"
            case DateOptions.week:
                return "Week"
            case DateOptions.twoWeeks:
                return "FortNight"
            case DateOptions.month:
                return "Month"
            case DateOptions.quarter:
                return "Quarter"
            case DateOptions.year:
                return "Year"
        }
    }
    useEffect(() => {
        fetchData()
    }, [lowStockQty, timeFrame])


    return (

        <div className="flex-col">
            <Hotlinks />
            <OrderTrackerView />
            <div className="card flex  bg-base-100 shadow-l mb-5">
                <div className="card-body">
                    <h2 className="card-title">Stock Amounts and Open Orders, Current Low Stock Amount Set At: {lowStockQty}</h2>
                    <Select label={"Low Stock Amount"}
                        options={[{ label: "100", value: "100" },
                        { label: "50", value: "50" },
                        { label: "25", value: "25" },
                        { label: "10", value: "10" }
                        ]}
                        value={{ value: `${lowStockQty}`, label: `${lowStockQty}` }}
                        onChange={(option: any) => {
                            setLowStockQty(Number(option.value))
                        }} />

                </div>
            </div>

            < GenralData data={genralDashboardData} />
            <br />
            <Graphs salesData={salesData} />

            <div className="card flex  bg-base-100 shadow-l mb-5 mt-5">
                <div className="card-body">
                    <h2 className="card-title">This {dateOptionToString(timeFrame)}</h2>
                    <Select label={"Time Frame"}
                        options={[{ label: "Day", value: DateOptions.day },
                        { label: "Week", value: DateOptions.week },
                        { label: "FortNight", value: DateOptions.twoWeeks },
                        { label: "Month", value: DateOptions.month },
                        { label: "Quarter", value: DateOptions.quarter },
                        { label: "Year", value: DateOptions.year },
                        ]}
                        value={{ value: timeFrame, label: dateOptionToString(timeFrame) }}
                        onChange={(option: any) => {
                            setTimeFrame(option.value)
                        }} />
                </div>
            </div>
            < TimeData data={timeDashboardData} />


        </div >
    )
}


export default Dashboard
