import React, { useState } from "react"

const Hotlinks = () => {

    const defaultLinks = [

        { toolTip: 'old website', name: 'old_bs', url: 'https://old.boardsource.xyz/' },
        { toolTip: 'shipping service', name: 'shippo', url: 'https://apps.goshippo.com/orders' },
        { toolTip: 'credit card service', name: 'stripe', url: 'https://dashboard.stripe.com/payments' },
        { toolTip: 'paypal payment', name: 'paypal', url: 'https://www.paypal.com/mep/dashboard' },
        { toolTip: 'frontend store website host', name: 'vercel', url: 'https://vercel.com/boardsource-s-team/store' },
        { toolTip: 'backend server host', name: 'digitalocean', url: 'https://cloud.digitalocean.com/projects/79c1e761-f1b7-4cad-8198-49c7be4b8ced/resources?i=ffb730' },
        { toolTip: 'server manager', name: 'pm2', url: 'https://app.pm2.io/bucket/64fb4ef4382ad11752ad140b/backend/overview/servers' },
        { toolTip: 'error monitoring', name: 'sentry', url: 'https://boardsource-ffcf4fe16.sentry.io/issues/?project=4505919819612160' },
        { toolTip: 'transactional email service', name: 'sendgrid', url: 'https://mc.sendgrid.com/dynamic-templates' },
        { toolTip: 'news letter email service', name: 'mailchimp', url: 'https://us20.admin.mailchimp.com/' },
        { toolTip: 'our github account', name: 'bs_github', url: 'https://github.com/boardsource' },
        { toolTip: 'the docs that are hosted on the client', name: 'docs_github', url: 'https://github.com/boardsource/docs' },
        { toolTip: 'front end ui css libary', name: 'daisyui', url: 'https://daisyui.com/' },
        { toolTip: 'back end e-com framwork', name: 'medusa', url: 'https://medusajs.com/' },
    ]

    const getLinks = (): string[] => {

        const tempLinks = localStorage.getItem('hotlinks')
        console.log(tempLinks, "tempLinks")
        if (tempLinks == null) {
            return []
        }
        return JSON.parse(tempLinks).links
    }

    const storLinks = (toStore: string[]) => {
        localStorage.setItem('hotlinks', JSON.stringify({ links: toStore }))
    }

    const deleteLink = (link) => {
        const tempLinks = links.filter(item => item !== link)
        setLinks(tempLinks)
        storLinks(tempLinks)
    }
    const [links, setLinks] = useState(getLinks()),
        [linkInput, setLinkInput] = useState('')
    const addLink = () => {
        const tempLinks = [...links, linkInput]
        setLinks(tempLinks)

        setLinkInput('')
        storLinks(tempLinks)
    }

    return (
        <div className="hotlinks card bg-base-100 shadow-xl mb-4">
            <div className="card-body">
                <h2 className="card-title">Helpful Links:</h2>
                <ul className="flex flex-wrap w-full">
                    {defaultLinks.map(link => (
                        <div className="tooltip" data-tip={`This is ${link.toolTip}`}>
                            <a className="badge badge-primary badge-outline mr-4" href={link.url}>{link.name}</a>
                        </div>
                    ))}
                </ul>
                <p>
                    Build your own list of shortcuts
                </p>
                <h2 className="card-title">Hot Links:</h2>

                <ul className="link-list">
                    {links.map(link => (
                        <li className="link-item flex w-full justify-between items-center" key={link}>
                            <a className="text-primary " href={link}>{link}</a>
                            <button className="btn btn-small " onClick={() => deleteLink(link)}>remove</button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="card-actions justify-end w-full p-4">

                <input className="input flex-1" placeholder="New Link" value={linkInput} onChange={e => setLinkInput(e.target.value)} />
                <button className="btn" onClick={addLink}>Add Link</button>
            </div>
        </div>
    )

}

export default Hotlinks
