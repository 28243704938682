import { Product } from "@medusajs/medusa"
import React from "react"
import EditIcon from "../../../../../components/fundamentals/icons/edit-icon"
import { ActionType } from "../../../../../components/molecules/actionables"
import Section from "../../../../../components/organisms/section"
import useToggleState from "../../../../../hooks/use-toggle-state"
import KitConfigModal from "./kitConfig-modal"
import clsx from "clsx"
import { ExtendedProduct, KitConfig } from "../../../../../types/shared"
import { KitConfigView } from "./KitConfigView"



type Props = {
    product: ExtendedProduct
}

const KitConfigSection = ({ product }: Props) => {
    const {
        state: infoState,
        close: closeInfo,
        toggle: toggleInfo,
    } = useToggleState()

    const actions: ActionType[] = [
        {
            label: "Edit Config",
            onClick: toggleInfo,
            icon: <EditIcon size={20} />,
        },
    ]
    console.log("product", product)
    const mData = product.metadata
    return (
        <Section title="Kit Config" actions={actions} forceDropdown>
            <div className="flex flex-col gap-y-xsmall mb-large mt-base">
                <h2 className="inter-base-semibold">Config Options</h2>
                <div className="flex flex-col gap-y-xsmall">
                    <div className="flex justify-between items-center inter-base-regular text-grey-50">
                        <p>Hidden</p>
                        <p>{mData && mData.hidden ? "This Product is Hidden" : "This product is Visible"}</p>
                    </div>
                    <div className="flex justify-between items-center inter-base-regular text-grey-50">
                        <p>Pack Size </p>
                        <p>{mData && mData.unitQty ? mData.unitQty : "unset"}: Per-pack</p>
                    </div>
                    <div className="flex justify-between items-center inter-base-regular text-grey-50">
                        <p>Self as Config Option</p>
                        <p>{mData && mData.selfAsConfig.selfAsConfig ? "Yes" : "No"}</p>
                    </div>
                    <div className="flex justify-between items-center inter-base-regular text-grey-50">
                        <p>Kit Config Image</p>
                        <div
                            className={clsx("w-1/6 ", {
                                hidden: mData && !mData.kitConfigImage ? !mData.kitConfigImage : false,
                            })}
                        >
                            {mData && mData.kitConfigImage && (
                                <div className="aspect-square flex items-center justify-center">
                                    <img
                                        src={mData.kitConfigImage}
                                        alt={`Thumbnail for ${product.title}`}
                                        className="object-contain rounded-rounded max-w-full max-h-full"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-between items-center inter-base-regular text-grey-50">
                        <p>Hover Image</p>
                        <div
                            className={clsx("w-1/6 ", {
                                hidden: mData && !mData.hoverImage ? !mData.hoverImage : false,
                            })}
                        >
                            {mData && mData.hoverImage && (
                                <div className="aspect-square flex items-center justify-center">
                                    <img
                                        src={mData.hoverImage}
                                        alt={`Thumbnail for ${product.title}`}
                                        className="object-contain rounded-rounded max-w-full max-h-full"
                                    />
                                </div>
                            )}
                        </div>
                    </div>


                    <div className="flex justify-between inter-base-regular text-grey-50">
                        <div className="flex flex-col">
                            <p className="inter-base-semibold">features</p>
                            <ul className="list-decimal">
                                {mData && mData.features ? mData.features.map((item, i) => (
                                    <li key={i}>{item}</li>
                                )) : <></>}
                            </ul>
                        </div>
                        <div className="flex flex-col">
                            <p className="inter-base-semibold">specifications</p>
                            <ul className="list-decimal">
                                {mData && mData.specifications ? mData.specifications.map((item, i) => (
                                    <li key={i}>{item}</li>
                                )) : <></>}
                            </ul>
                        </div>
                        <div className="flex flex-col">
                            <p className="inter-base-semibold">docs</p>
                            <ul className="list-decimal">
                                {mData && mData.docs ? mData.docs.map((item, i) => (
                                    <li key={i}>{item}</li>
                                )) : <></>}
                            </ul>
                        </div>
                    </div>
                    {mData && mData.kitConfig ? mData.kitConfig.map((config, index) => <div key={index} className="mt-base bg-grey-5 rounded-rounded px-base py-xsmall">

                        <KitConfigView
                            kitConfig={config}
                            index={index}
                        />
                    </div>) : <></>}
                </div>
            </div>

            <KitConfigModal product={product} open={infoState} onClose={closeInfo} />

        </Section>
    )
}

export default KitConfigSection
