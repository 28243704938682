import Switch from "../../../../../components/atoms/switch"
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../../components/fundamentals/button";
import { CreateTopBarInput, TopBar, TopBarLevel, TopBarPersistence } from "../../../../../types/shared";
import InputField from "../../../../../components/molecules/input";
import { NextSelect } from "../../../../../components/molecules/select/next-select";
import DatePicker from "../../../../../components/atoms/date-picker/date-picker";
import TimePicker from "../../../../../components/atoms/date-picker/time-picker";

const levelOptions = [
    {
        label: "default",
        value: "default",
    },
    {
        label: "warn",
        value: "warn",
    },
    {
        label: "alert",
        value: "alert",
    },

]
const persistenceOptions = [
    {
        label: "closeable",
        value: "closeable",
    },
    {
        label: "nag",
        value: "nag",
    },
    {
        label: "always",
        value: "always",
    },

]

type Props = {
    topBar?: TopBar
    onSubmit: (topBar: TopBar | CreateTopBarInput) => void
}
type CreateTopBarInputForm = {
    value: string
    persistence: {
        label: string;
        value: string;
    }
    level: {
        label: string;
        value: string;
    }
    active: boolean
    startTime: string | null
    endTime: string | null
    metadata: Record<string, unknown> | null
}



const TopBarForm = ({ topBar, onSubmit: propsOnSubmit }: Props) => {
    const { register, handleSubmit, watch, reset, formState: { errors, isDirty }, control } =
        useForm<CreateTopBarInputForm>({ defaultValues: getDefaultValues(topBar) });
    const onSubmit = (data) => {
        let valueTopBar: CreateTopBarInput = {
            value: data.value,
            persistence: data.persistence.value,
            level: data.level.value,
            active: data.active,
            startTime: data.startTime ? `${data.startTime}` : null,
            endTime: data.endTime ? `${data.endTime}` : null,
            metadata: null,
        }
        propsOnSubmit(valueTopBar)
    }

    useEffect(() => {
        reset(getDefaultValues(topBar))
    }, [topBar])


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h3>Active:</h3>
            <Controller
                control={control}
                name={"active"}
                render={({ field: { value, onChange } }) => {
                    return <Switch checked={value} onCheckedChange={onChange} />
                }}
            />
            <h3>Value:</h3>
            <Controller
                control={control}
                name={"value"}
                render={({ field: { value, onChange } }) => {
                    return <InputField
                        placeholder="value"
                        onChange={onChange}
                        value={value}
                    />
                }}
            />

            {errors.value && <span>This field is required</span>}

            <Controller
                name={"level"}
                control={control}
                render={({ field: { value, onChange } }) => {
                    return (
                        <NextSelect
                            label={`Level:`}
                            placeholder="Choose a level"
                            //@ts-ignore
                            options={levelOptions}
                            onChange={onChange}
                            value={value}
                        />
                    )
                }}
            />
            <Controller
                name={"persistence"}
                control={control}
                render={({ field }) => {
                    return (
                        <NextSelect
                            label={`Persistence:`}

                            placeholder="Choose a persistence"
                            //@ts-ignore
                            options={persistenceOptions}
                            {...field}
                        />
                    )
                }}
            />
            <h3>Start and end times:</h3>
            <div className="flex">
                <Controller
                    name="startTime"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        const ensuredDate = value || new Date()
                        return (
                            <>
                                <DatePicker
                                    //@ts-ignore
                                    date={ensuredDate}
                                    label="Start Date"
                                    onSubmitDate={onChange}
                                />
                                <TimePicker
                                    //@ts-ignore
                                    date={ensuredDate}
                                    label="Start Time"
                                    onSubmitDate={onChange}
                                />
                            </>
                        )
                    }}
                />
                <Controller
                    name="endTime"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        const ensuredDate = value || new Date()
                        return (
                            <>
                                <DatePicker
                                    //@ts-ignore
                                    date={ensuredDate}
                                    label="End Date"
                                    onSubmitDate={onChange}
                                />
                                <TimePicker
                                    //@ts-ignore
                                    date={ensuredDate}
                                    label="End Time"
                                    onSubmitDate={onChange}
                                />
                            </>
                        )
                    }}
                />
            </div>

            <Button
                className="mt-2"
                size="small"
                variant="primary"
                type="submit"
                disabled={!isDirty}
            >
                Save
            </Button>
        </form>
    )
}
const getDefaultValues = (topBar: TopBar | undefined) => {
    let emptyTopBar = {
        value: "",
        persistence: {
            label: "closeable",
            value: "closeable",
        },
        level: {
            label: "default",
            value: "default",
        },
        active: false,
        startTime: null,
        endTime: null,
        metadata: null,
    }
    if (topBar) {
        for (const [key, value] of Object.entries(topBar)) {
            if (value && value !== undefined) {
                if (key === "persistence" || key === "level") {
                    emptyTopBar[key] = {
                        label: value,
                        value: value,
                    }
                } else if (key === "startTime" || key === "endTime") {
                    //@ts-ignore
                    emptyTopBar[key] = new Date(value)
                } else {
                    emptyTopBar[key] = value
                }
            }

        }

    }
    return emptyTopBar
}


export default TopBarForm
