import React, { useState } from "react"
import { GenralDashboardData, TimeDashboardData } from "../../../types/shared"


type TimeCompaireDisplayStatProps = {
    currentNumber: number
    pastNumber: number
    displayAsCurrency: boolean
    title: string
}
type BasicStatProps = {
    stat: number | string
    displayAsCurrency: boolean
    title: string
}
type RowProps = {
    children: any
    vertical?: boolean
}


export const TimeCompaireDisplayStat = ({ currentNumber, pastNumber, displayAsCurrency, title }: TimeCompaireDisplayStatProps) => {
    const round = (num: number, overRide?: boolean) => {
        if (displayAsCurrency && !overRide) {
            return `$${Math.round(num / 100).toLocaleString()}`
        }
        return Math.round(num)
    }
    const getOpprator = () => {
        if (currentNumber > pastNumber) {
            const opprater = currentNumber - pastNumber
            const isIncrease = true
            return { opprater, isIncrease }
        } else {
            const opprater = pastNumber - currentNumber
            const isIncrease = false
            return { opprater, isIncrease }
        }
    }

    const getPercentageChange = () => {
        const { opprater, isIncrease } = getOpprator()
        return { change: (opprater / currentNumber) * 100, isIncrease }
    }
    return (
        <div className="stat">
            <div className="stat-title">
                {title}
            </div>
            <div className="stat-value">
                {round(currentNumber)}
            </div>
            <div className="stat-desc">
                {round(pastNumber)}  {getPercentageChange().isIncrease ? "↗︎" : "↘︎"}{round(getPercentageChange().change, true)}%
            </div>
        </div>
    )

}
export const BasicStat = ({ stat, displayAsCurrency, title }: BasicStatProps) => {
    const round = (num: number, overRide?: boolean) => {
        if (displayAsCurrency && overRide === false) {
            return `$${Math.round(num / 100).toLocaleString()}`
        }
        return Math.round(num)
    }
    return (
        <div className="stat">
            <div className="stat-title">
                {title}
            </div>
            <div className="stat-value">
                {typeof stat === "string" ? (
                    <p className="text-xs" >
                        {stat}
                    </p>

                ) : round(stat)}
            </div>
        </div>
    )

}

export const StatRow = ({ children, vertical }: RowProps) => {

    return (
        <div className={`stats ${vertical ? "stats-vertical" : "stats-horizontal"} shadow-xl`}>
            {children}
        </div>
    )

}
