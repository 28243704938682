import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import SortableMultiSelect from "../../../components/atoms/sortable-multi-select"
import Button from "../../../components/fundamentals/button"
import InputField from "../../../components/molecules/input"
import Modal from "../../../components/molecules/modal"
import { OrderTracker, CreateOrderTrackerInput, OrderTrackerStatus } from "../../../types/shared"
import Switch from "../../../components/atoms/switch"
import Select from "../../../components/molecules/select/next-select/select"
import TextArea from "../../../components/molecules/textarea"




type Props = {
    orderTracker?: OrderTracker
    open: boolean
    onClose: () => void
    handelSubmit: (orderTracker: CreateOrderTrackerInput) => void
}

type CreateOrderTrackerInputFourm = Omit<CreateOrderTrackerInput, "metadata" & "status"> & {
    metadata: { trackingLink: string; productLink: string, notes: string }, status: {
        label: string

        value: OrderTrackerStatus
    }
}



const OrderTrackerModal = ({ orderTracker, open, onClose, handelSubmit }: Props) => {

    const { register, handleSubmit, watch, reset, formState: { errors, isDirty }, control } =
        // @ts-ignore
        useForm<CreateOrderTrackerInputFourm>({ defaultValues: getDefaultValues(orderTracker) });

    const onSubmit = (data) => {
        let orderTracker: CreateOrderTrackerInput = {
            title: data.title,
            oos: data.oos,
            currentlySold: data.currentlySold,
            status: data.status.value,
            metadata: { trackingLink: data.metadata.trackingLink, productLink: data.metadata.productLink, notes: data.metadata.notes }
        }
        console.log(orderTracker)
        handelSubmit({ ...orderTracker })

    }

    return (
        <Modal open={open} handleClose={onClose} isLargeModal>
            <Modal.Body>
                <Modal.Header handleClose={onClose}>
                    <h1 className="inter-xlarge-semibold m-0">
                        Track An Order
                    </h1>
                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <Modal.Content>
                        <p className="inter-small-semibold text-grey-50">Description</p>
                        <Controller
                            control={control}
                            name={`title`}
                            render={({ field: { value, onChange } }) => {
                                return <InputField
                                    placeholder="title"
                                    onChange={onChange}
                                    value={value}
                                />
                            }}
                        />



                        <p className="inter-small-semibold text-grey-50">Currently Being Sold </p>

                        <Controller
                            control={control}
                            name={`currentlySold`}
                            render={({ field: { value, onChange } }) => {
                                return <Switch checked={value} onCheckedChange={onChange} />
                            }}
                        />
                        <p className="inter-small-semibold text-grey-50">OOS</p>

                        <Controller
                            control={control}
                            name={`oos`}
                            render={({ field: { value, onChange } }) => {
                                return <Switch checked={value} onCheckedChange={onChange} />
                            }}
                        />

                        <p className="inter-small-semibold text-grey-50">status</p>
                        <Controller
                            control={control}
                            name={`status`}
                            render={({ field }) => {
                                return (
                                    <Select

                                        options={[
                                            {
                                                label: "Needs to order",
                                                //@ts-ignore
                                                value: OrderTrackerStatus.NEEDS_TO_ORDER
                                            },
                                            {
                                                label: "Ordered",
                                                //@ts-ignore

                                                value: OrderTrackerStatus.ORDERED
                                            },
                                            {
                                                label: "Shipped",
                                                //@ts-ignore

                                                value: OrderTrackerStatus.SHIPPED
                                            },
                                            {
                                                label: "Recieved",
                                                //@ts-ignore

                                                value: OrderTrackerStatus.RECIEVED
                                            },
                                            {
                                                label: "Needs to be listed",
                                                //@ts-ignore

                                                value: OrderTrackerStatus.NEED_TO_BE_LISTED
                                            },
                                        ]}

                                        value={field.value}
                                        onChange={field.onChange}

                                    />

                                )
                            }}
                        />
                        <Controller
                            control={control}
                            name={`metadata.notes`}
                            render={({ field: { value, onChange } }) => {
                                return <TextArea
                                    placeholder="Bought on 10/26/1985 expeted ship 3 weeks"
                                    onChange={onChange}
                                    value={value} label={"Notes"} />
                            }}
                        />
                        <p className="inter-small-semibold text-grey-50">tracking Link</p>
                        <Controller
                            control={control}
                            name={`metadata.trackingLink`}
                            render={({ field: { value, onChange } }) => {
                                return <InputField
                                    placeholder="tracking link"
                                    onChange={onChange}
                                    value={value}
                                />
                            }}
                        />

                        <p className="inter-small-semibold text-grey-50">product link</p>
                        <Controller
                            control={control}
                            name={`metadata.productLink`}
                            render={({ field: { value, onChange } }) => {
                                return <InputField
                                    placeholder="Product Link"
                                    onChange={onChange}
                                    value={value}
                                />
                            }}
                        />




                    </Modal.Content>
                    <Modal.Footer>
                        <div className="flex gap-x-2 justify-end w-full">
                            <Button
                                size="small"
                                variant="secondary"
                                type="button"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="secondary"
                                type="submit"
                            >
                                Save
                            </Button>

                        </div>
                    </Modal.Footer>
                </form>

            </Modal.Body>
        </Modal>
    )
}



const getDefaultValues = (orderTracker: OrderTracker | undefined) => {
    //@ts-ignore
    let emptyorderTracker: CreateOrderTrackerInputFourm = {
        title: "",
        oos: false,
        currentlySold: false,
        // @ts-ignore
        status: {
            label: "Needs to be listed",
            value: OrderTrackerStatus.NEEDS_TO_ORDER
        },
        metadata: { trackingLink: "", productLink: "", notes: "" }
    }


    if (orderTracker) {
        for (const [key, value] of Object.entries(orderTracker)) {
            if (value && value !== undefined) {
                if (key === "metadata" && orderTracker.metadata && typeof orderTracker.metadata === "object") {
                    emptyorderTracker.metadata = {
                        //@ts-ignore
                        trackingLink: orderTracker.metadata.trackingLink ? orderTracker.metadata.trackingLink : "",
                        //@ts-ignore

                        productLink: orderTracker.metadata.productLink ? orderTracker.metadata.productLink : "",
                        //@ts-ignore

                        notes: orderTracker.metadata.notes ? orderTracker.metadata.notes : ""
                    }
                } else if (key === "status") {
                    //@ts-ignore
                    emptyorderTracker.status = {
                        label: orderTracker.status,
                        //@ts-ignore
                        value: orderTracker.status
                    }
                } else {
                    emptyorderTracker[key] = value
                }
            }

        }

    }

    return emptyorderTracker
}


export default OrderTrackerModal
