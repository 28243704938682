import React, { MouseEventHandler } from 'react';

import Select, {
    components,
    MultiValueGenericProps,
    MultiValueProps,
    OnChangeValue,
    Props,
} from 'react-select';
import {
    SortableContainer,
    SortableContainerProps,
    SortableElement,
    SortEndHandler,
    SortableHandle,
} from 'react-sortable-hoc';

function arrayMove<T>(array: readonly T[], from: number, to: number) {
    const slicedArray = array.slice();
    slicedArray.splice(
        to < 0 ? array.length + to : to,
        0,
        slicedArray.splice(from, 1)[0]
    );
    return slicedArray;
}
type Option = { value: string; label: string };

const SortableMultiValue = SortableElement(
    (props: MultiValueProps<Option>) => {
        // this prevents the menu from being opened/closed when the user clicks
        // on a value to begin dragging it. ideally, detecting a click (instead of
        // a drag) would still focus the control and toggle the menu, but that
        // requires some magic with refs that are out of scope for this example
        const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };
        const innerProps = { ...props.innerProps, onMouseDown };
        return <components.MultiValue {...props} innerProps={innerProps} />;
    }
);

const SortableMultiValueLabel = SortableHandle(
    (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
    Props<Option, true> & SortableContainerProps
>;

type CustomProps = {
    options: Option[], value: Option[], onChange: (selectedOptions: Option[]) => void,
    className?: string
}

export default function SortableMultiSelect(props: CustomProps) {
    const onChange = (selectedOptions: OnChangeValue<Option, true>) => {
        props.onChange(selectedOptions as Option[])
    }
    const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(props.value, oldIndex, newIndex);
        props.onChange(newValue);
        console.log(
            'Values sorted:',
            newValue.map((i) => i.value)
        );
    };

    return (
        <div className={`min-h-[250px] ${props.className ?? ''}`}
        >
            <SortableSelect
                useDragHandle
                // react-sortable-hoc props:
                axis="xy"
                onSortEnd={onSortEnd}
                distance={4}
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                // react-select props:
                isMulti
                isSearchable
                options={props.options}
                value={props.value}
                onChange={onChange}
                className='max-height-[200px] '
                components={{
                    // @ts-ignore We're failing to provide a required index prop to SortableElement
                    MultiValue: SortableMultiValue,
                    //@ts-ignore
                    MultiValueLabel: SortableMultiValueLabel,
                }}
                styles={{
                    menuList: (base) => ({
                        ...base,
                        maxHeight: '155px',
                        overflow: 'auto',

                    }),
                }}
                closeMenuOnSelect={false}
            />
        </div>
    );
}
