import { Product } from "@medusajs/medusa"
import { ExtendedProduct } from "../types/shared"

export enum ProductErrorLevel {
    high = "Need To Fix",
    medium = "Probably Should Fix",
    low = "Just so You Know"
}
export type ProductTestResult = {
    title: string
    description: string
    level: ProductErrorLevel

}
const checkObject = (object: Record<string, any>, key: string, type: "string" | "int" | "bool" | "array"): boolean => {
    if (object && object.hasOwnProperty(key) && object[key]) {
        switch (type) {
            case "string":
                return typeof object[key] == "string" && object[key] !== "" && object[key] !== " "
            case "int":
                return typeof object[key] == "number"
            case "bool":
                return typeof object[key] == "boolean"
            case "array":
                return Array.isArray(object[key])

        }
    }
    return false

}

const generalInformationTests = (product: Product): ProductTestResult[] => {
    const requiredTags = ["Keycaps", "Switches", "Solderable Kits", "Premium Products", "Components"]
    const results: ProductTestResult[] = []
    if (!checkObject(product, "title", "string")) {
        results.push({
            title: "Title is missing",
            description: "A product title is required for the product to be published",
            level: ProductErrorLevel.high
        })
    }
    if (!checkObject(product, "handle", "string")) {
        results.push({
            title: "Handle is missing",
            description: "A product handle is required for the product to be published",
            level: ProductErrorLevel.high
        })
    }
    if (!checkObject(product, "description", "string")) {
        results.push({
            title: "Description is missing",
            description: "A product description is required for the product to be published",
            level: ProductErrorLevel.high
        })
    }
    if (checkObject(product, "tags", "array")) {
        const currentTags = product.tags.map(tag => tag.value)
        const hasTag = requiredTags.find(tag => currentTags.includes(tag))
        if (hasTag === undefined) {
            results.push({
                title: "Tags are missing",
                description: `A product needs a tag to be seen on the site. Please include one of these ${requiredTags.join(", ")}`,
                level: ProductErrorLevel.high
            })
        }

    } else {
        results.push({
            title: "Tags are missing",
            description: `A product needs a tag to be seen on the site. Please include one of these ${requiredTags.join(", ")}`,
            level: ProductErrorLevel.high
        })
    }

    return results


}
const attributesTest = (product: Product): ProductTestResult[] => {
    const results: ProductTestResult[] = []
    if (!checkObject(product, "hs_code", "string")) {
        results.push({
            title: "hs_code is missing",
            description: "A product hs_code is required for the product to be published",
            level: ProductErrorLevel.high
        })
    }
    if (!checkObject(product, "origin_country", "string")) {
        results.push({
            title: "Origin_country is missing",
            description: "A product origin_country is required for the product to be published",
            level: ProductErrorLevel.high
        })
    }
    if (!checkObject(product, "weight", "int")) {
        results.push({
            title: "Weight is missing",
            description: "A product weight is required for the product to be published",
            level: ProductErrorLevel.high
        })
    }
    if (!checkObject(product, "height", "int")) {
        results.push({
            title: "Height is missing",
            description: "A product height is required for the product to be published",
            level: ProductErrorLevel.high
        })
    }
    if (!checkObject(product, "width", "int")) {
        results.push({
            title: "Width is missing",
            description: "A product width is required for the product to be published",
            level: ProductErrorLevel.high
        })
    }
    if (!checkObject(product, "length", "int")) {
        results.push({
            title: "Length is missing",
            description: "A product length",
            level: ProductErrorLevel.high
        })
    }
    return results
}
const imagesTest = (product: Product): ProductTestResult[] => {
    const results: ProductTestResult[] = []
    if (!checkObject(product, "images", "array")) {
        results.push({
            title: "Images are missing",
            description: "A product needs at least one image to be seen on the site",
            level: ProductErrorLevel.high
        })
    } else {
        if (product.images.length === 0) {
            results.push({
                title: "Images are missing",
                description: "A product needs at least one image to be seen on the site",
                level: ProductErrorLevel.high
            })
        }
    }
    if (!checkObject(product, "thumbnail", "string")) {
        results.push({
            title: "Thumbnail is missing",
            description: "A product needs a thumbnail to be seen on the site",
            level: ProductErrorLevel.high
        })
    }
    return results
}

const variantsTest = (product: Product): ProductTestResult[] => {
    const results: ProductTestResult[] = []
    if (!checkObject(product, "variants", "array")) {
        results.push({
            title: "Variants are missing",
            description: "A product needs at least one variant to be seen on the site",
            level: ProductErrorLevel.high
        })
    } else {
        if (product.variants.length === 0) {
            results.push({
                title: "Variants are missing",
                description: "A product needs at least one variant to be seen on the site",
                level: ProductErrorLevel.high
            })
        }
        else {
            product.variants.forEach(variant => {

                if (!checkObject(variant, "title", "string")) {
                    results.push({
                        title: "Variant title is missing",
                        description: "A product variant needs a title to be published",
                        level: ProductErrorLevel.high
                    })
                }
                if (!checkObject(variant.metadata, "kitConfigImage", "string")) {
                    results.push({
                        title: `Variant ${variant.title} kit Config Image is missing`,
                        description: "A product variant should have a kit Config Image",
                        level: ProductErrorLevel.medium
                    })
                }
                if (!checkObject(variant.metadata, "kitConfigMaterialImage", "string")) {
                    results.push({
                        title: `Variant ${variant.title} kit Config Material Image is missing`,
                        description: "You might want a kit Config Material Image to be published",
                        level: ProductErrorLevel.low
                    })
                }
                if (!checkObject(variant, "hs_code", "string")) {
                    results.push({
                        title: `Variant ${variant.title} hs_code is missing`,
                        description: "A product variant needs a hs_code to be published",
                        level: ProductErrorLevel.high
                    })
                }
                if (!checkObject(variant, "prices", "array")) {
                    results.push({
                        title: `Variant ${variant.title} price is missing`,
                        description: "A product variant needs a price to be published",
                        level: ProductErrorLevel.high
                    })
                }
                if (!checkObject(variant, "origin_country", "string")) {
                    results.push({
                        title: `Variant ${variant.title} origin_country is missing`,
                        description: "A product variant needs a origin_country to be published",
                        level: ProductErrorLevel.high
                    })
                }
                if (!checkObject(variant, "weight", "int")) {
                    results.push({
                        title: `Variant ${variant.title} weight is missing`,
                        description: "A product variant needs a weight to be published",
                        level: ProductErrorLevel.high
                    })
                }
                if (!checkObject(variant, "height", "int")) {
                    results.push({
                        title: `Variant ${variant.title} height is missing`,
                        description: "A product variant needs a height to be published",
                        level: ProductErrorLevel.high
                    })
                }
                if (!checkObject(variant, "width", "int")) {
                    results.push({
                        title: `Variant ${variant.title} width is missing`,
                        description: "A product variant needs a width to be published",
                        level: ProductErrorLevel.high
                    })
                }
                if (!checkObject(variant, "length", "int")) {
                    results.push({
                        title: `Variant ${variant.title} length is missing`,
                        description: "A product variant needs a length to be published",
                        level: ProductErrorLevel.high
                    })
                }
                if (!checkObject(variant, "inventory_quantity", "int")) {
                    results.push({
                        title: `Variant ${variant.title} inventory quantity is missing`,
                        description: "A product variant needs an inventory quantity to be published",
                        level: ProductErrorLevel.high
                    })
                }


            })
        }
    }
    return results
}
const metadataTest = (product: ExtendedProduct): ProductTestResult[] => {
    const results: ProductTestResult[] = []
    const metadata = product.metadata
    if (!metadata || typeof metadata !== "object") {
        results.push({
            title: "Metadata is missing",
            description: "A product needs metadata to be published",
            level: ProductErrorLevel.high
        })
        return results
    }
    if (!checkObject(metadata, "docs", "array")) {
        results.push({
            title: "Metadata is missing docs",
            description: "A product needs docs to be published",
            level: ProductErrorLevel.high
        })
    } else {
        if (metadata.docs.length === 0) {
            results.push({
                title: "Metadata is missing docs",
                description: "A product should have at least one doc",
                level: ProductErrorLevel.medium
            })
        }
    }
    if (!checkObject(metadata, "kitConfigImage", "string")) {
        results.push({
            title: "Metadata is missing kitConfigImage",
            description: "A product needs kitConfigImage to be published",
            level: ProductErrorLevel.high
        })
    }
    if (!checkObject(metadata, "kitConfigMaterialImage", "string")) {
        results.push({
            title: "Metadata is missing kitConfigMaterialImage",
            description: "You might want a kitConfigMaterialImage",
            level: ProductErrorLevel.low
        })
    }
    if (checkObject(metadata, "digitalProduct", "bool") && metadata.digitalProduct && !checkObject(metadata, "digitalProductEmailId", "string")) {
        results.push({
            title: "Metadata is missing digitalProductEmailId",
            description: "Product is marked as digital but is missing a email id ",
            level: ProductErrorLevel.high
        })
    }
    if (checkObject(metadata, "reservationProduct", "bool") && metadata.digitalProduct && !checkObject(metadata, "digitalProductEmailId", "string")) {
        results.push({
            title: "Metadata is missing digitalProductEmailId",
            description: "Product is marked as reservation Product but is missing a email id ",
            level: ProductErrorLevel.high
        })
    }
    if (checkObject(metadata, "physicalProductForReservation", "bool") && metadata.digitalProduct && !checkObject(metadata, "preOrderId", "string")) {
        results.push({
            title: "Metadata is missing preOrderId",
            description: "Product is marked as reservation Product but is missing a preOrderId ",
            level: ProductErrorLevel.high
        })
    }
    if (checkObject(metadata, "reservationProduct", "bool") && metadata.digitalProduct && !checkObject(metadata, "preOrderId", "string")) {
        results.push({
            title: "Metadata is missing preOrderId",
            description: "Product is marked as reservation Product but is missing a preOrderId ",
            level: ProductErrorLevel.high
        })
    }


    return results
}


export const productTest = (product: ExtendedProduct): ProductTestResult[] => {
    return [...generalInformationTests(product), ...variantsTest(product), ...imagesTest(product), ...attributesTest(product), ...metadataTest(product)]
}