
import React from "react"
import { Controller } from "react-hook-form"
import Switch from "../../../../../components/atoms/switch"
import IndeterminateCheckbox from "../../../../../components/molecules/indeterminate-checkbox"
import { NextSelect } from "../../../../../components/molecules/select/next-select"
import { NestedForm } from "../../../../../utils/nested-form"
import InputField from "../../../../../components/molecules/input"

export type UnitQtyType = {
    unitQty: number

}

type UnitQtyFormProps = {
    form: NestedForm<UnitQtyType>
}

const UnitQtyForm = ({ form }: UnitQtyFormProps) => {
    const {
        register,
        path,
        control,
        formState: { errors },
    } = form


    return (
        <div>
            <div className="flex items-center justify-between mb-2xsmall">
                <h2 className="inter-base-semibold">Unit Qty</h2>
                <InputField
                    label="Unit Quantity"
                    type="number"
                    placeholder="10..."
                    errors={errors}
                    {...register(path("unitQty"), {
                        valueAsNumber: true,
                    })}
                />

            </div>
            <p className="inter-base-regular text-grey-50">
                are they sold id packs of 10,1,50 so on            </p>
        </div>
    )
}

export default UnitQtyForm

