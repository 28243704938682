import React from "react"
import { TopBar, TopBarLevel, TopBarPersistence } from "../../../../../types/shared"

const TopBarView = ({ onClick, topBar }: { topBar: TopBar, onClick: (topBar: TopBar) => void }) => {
    const returnLevelClass = () => {
        switch (topBar.level) {
            case TopBarLevel.DEFAULT:
                return "badge-info"
            case TopBarLevel.WARN:
                return "badge-warning"

            case TopBarLevel.ALERT:
                return "badge-error "

        }
    }
    const returnPersistenceClass = () => {
        switch (topBar.persistence) {
            case TopBarPersistence.CLOSEABLE:
                return "badge-success"
            case TopBarPersistence.NAG:
                return "badge-warning"
            case TopBarPersistence.ALWAYS:
                return "badge-error "

        }
    }
    return (
        <div className="flex flex-col card bg-base-300 rounded-box mb-2" onClick={() => onClick(topBar)}>
            <div className="flex pt-2 flex justify-around">
                <div className={`badge whitespace-nowrap ${topBar.active ? "badge-success" : "badge-error"}`}>
                    {topBar.active ? "Active" : "Not-Active"}
                </div>
                <div className="">
                    Value:{topBar.value}
                </div>
            </div>
            <div className="divider"></div>
            <div className="flex pb-2 flex-wrap flex justify-around">

                <div className={`badge whitespace-nowrap mb-1 ${returnLevelClass()}`}>
                    Level: {topBar.level}
                </div>
                <div className={`badge whitespace-nowrap mb-1 ${returnPersistenceClass()}`}>
                    Persistence: {topBar.persistence}
                </div>
                <div className="tooltip tooltip-info" data-tip={topBar.startTime}>
                    <div className={`badge whitespace-nowrap mb-1 badge-outline ${topBar.startTime === null ? "badge-error" : "badge-info"}`}>
                        Start-Time: {topBar.startTime ? "Hover" : "not-set"}
                    </div>
                </div>

                <div className="tooltip tooltip-info" data-tip={topBar.endTime}>
                    <div className={`badge whitespace-nowrap mb-1 badge-outline ${topBar.endTime === null ? "badge-error" : "badge-info"}`}>
                        End-Time: {topBar.endTime ? "Hover" : "not-set"}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TopBarView
