import React, { useEffect, useMemo, useState } from "react"

import Medusa from "../../../services/api"

import SimpleTable from "../../../domain/dashboard/table"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"
import { KitConfig, KitConfigProduct, PreBuiltKitConfig } from "../../../types/shared"
import PrebuiltKitConfigModal from "./prebuilt-kitconfig-modal"
import KitConfigModal from "../kit-config/kitconfig-modal"
import KitConfigProductModal from "../kitconfig-products/kit-config-product/modal"


const PrebuiltKitConfigIndex = () => {
    const notification = useNotification()

    const [showPrebuiltKitConfig, setShowPrebuiltKitConfig] = useState(false),
        [selectedPrebuiltConfig, setSelectedPrebuiltConfig] = useState<PreBuiltKitConfig | undefined>(),
        [prebuiltkitconfigs, setPrebuiltkitconfigs] = useState<PreBuiltKitConfig[] | undefined>(),
        [filteredPrebuiltkitconfigs, setFilteredPrebuiltkitconfigs] = useState<PreBuiltKitConfig[]>([]),
        [search, setSearch] = useState(""),
        [showKitConfigProduct, setShowKitConfigProduct] = useState(false),
        [showKitConfig, setShowKitConfig] = useState(false),
        [selectedproduct, setSelectedproduct] = useState<KitConfigProduct | undefined>(),
        [selectedConfig, setSelectedConfig] = useState<KitConfig | undefined>()

    const getKitconfigs = async () => {
        const res = await Medusa.customRoutes.getPrebuiltkitconfigs()
        setPrebuiltkitconfigs(res.data)
        setFilteredPrebuiltkitconfigs(res.data)
    }
    const handleEditKitconfig = async (data) => {
        try {
            if (selectedConfig) {

                await Medusa.customRoutes.updateKitConfig(selectedConfig.id, data)
                setShowKitConfig(false)
                setSelectedConfig(undefined)
                notification("Success", "Successfully created KitConfig", "success")
            } else {
                console.log("no product selected")
            }

        } catch (error) {
            notification("Error", getErrorMessage(error), "error")
            console.log(error)
        }
    }
    const handleEditKitconfigProduct = async (data) => {
        try {
            if (selectedproduct) {
                const res = await Medusa.customRoutes.updateKitConfigProduct(selectedproduct.id, data)
                setShowKitConfigProduct(false)
                setSelectedproduct(undefined)
                notification("Success", "Successfully created KitConfigProduct", "success")
            } else {
                console.log("no product selected")
            }

        } catch (error) {
            notification("Error", getErrorMessage(error), "error")
            console.log(error)
        }
    }

    const handleEditPreBuiltKitconfig = async (data) => {
        try {
            if (selectedConfig) {

                await Medusa.customRoutes.updatePrebuiltkitconfig(selectedConfig.id, data)
                setShowPrebuiltKitConfig(false)
                setSelectedConfig(undefined)
                notification("Success", "Successfully created PrebuiltKitConfig", "success")
                getKitconfigs()

            } else {
                console.log("no product selected")
            }

        } catch (error) {
            notification("Error", getErrorMessage(error), "error")
            console.log(error)
        }
    }



    useEffect(() => {

        if (prebuiltkitconfigs === undefined) {
            getKitconfigs()
        }
    }, [prebuiltkitconfigs])
    const handleSearchInput = (e) => {
        const tempSearch = e.target.value
        setSearch(tempSearch)
        const searchResult = prebuiltkitconfigs ? prebuiltkitconfigs?.filter((prebuiltkitconfig) =>
            JSON.stringify(prebuiltkitconfig).toLowerCase().includes(tempSearch.toLowerCase())
        ) : []
        setFilteredPrebuiltkitconfigs(searchResult)
    }

    return (
        <div className="flex flex-col grow h-full">
            <div className="w-full flex flex-col grow">
                <input type="text" placeholder="Search here" className="input input-bordered w-full max-w-xs mb-4" value={search} onChange={handleSearchInput} />

                <SimpleTable
                    title={"Pre-built KitConfigs"}
                    className="mb-4"
                    noMaxHeight
                    heads={["title", "description", "kit configs"]}>
                    {filteredPrebuiltkitconfigs.map((prebuiltkitconfig, index) => (

                        <tr

                            className="mt-base bg-grey-5 rounded-rounded px-base py-xsmall"

                            key={prebuiltkitconfig.id}>
                            <th>
                                <button
                                    onClick={() => {
                                        setShowPrebuiltKitConfig(true)
                                        setSelectedPrebuiltConfig(prebuiltkitconfig)
                                    }}
                                    className="btn btn-xs tooltip" data-tip="Edit pre-built kitconfig" >
                                    {prebuiltkitconfig.title}
                                </button>



                            </th>
                            <td>

                                {prebuiltkitconfig.description}

                            </td>
                            <td>
                                <SimpleTable
                                    title={"Kitconfig Products"}
                                    className="mb-4"
                                    noMaxHeight
                                    heads={["title", "mustSelect", "selectOne", "products",]}>
                                    {prebuiltkitconfig.kitConfig.map((singleKitConfig) => (

                                        <tr key={singleKitConfig.id}>
                                            <td >
                                                <button onClick={() => {
                                                    setShowKitConfig(true)
                                                    setSelectedConfig(singleKitConfig)
                                                }} className="btn btn-xs tooltip tooltip-right" data-tip="Edit group" >
                                                    {singleKitConfig.title}
                                                </button>


                                            </td>
                                            <td>
                                                {singleKitConfig.mustSelect ? "Must Select" : "Optional"}


                                            </td>
                                            <td>
                                                {singleKitConfig.selectOne ? "One" : "Multiple"}


                                            </td>
                                            <td className=" flex">
                                                {singleKitConfig.kitConfigProducts.map(kitConfigProduct =>
                                                (
                                                    <button
                                                        onClick={() => {
                                                            setShowKitConfigProduct(true)
                                                            //@ts-ignore
                                                            setSelectedproduct(kitConfigProduct)
                                                        }}
                                                        className="btn btn-xs mr-1 tooltip" data-tip="Edit config product" key={kitConfigProduct.product_id}>
                                                        {kitConfigProduct.product_name} | {kitConfigProduct.qtyNeeded}x{kitConfigProduct.preSelected ? " | Selected" : ""}
                                                    </button>

                                                ))
                                                }
                                            </td>


                                        </tr>


                                    ))}
                                </SimpleTable>

                            </td>



                        </tr>

                    ))}
                </SimpleTable>

            </div>

            {showKitConfigProduct && selectedproduct !== undefined && (
                <KitConfigProductModal
                    kitconfigProduct={selectedproduct}
                    onClose={() => setShowKitConfigProduct(!showKitConfigProduct)}
                    handelSubmit={handleEditKitconfigProduct} open={showKitConfigProduct} />
            )}
            {showKitConfig && selectedConfig !== undefined && (
                <KitConfigModal
                    kitconfig={selectedConfig}
                    onClose={() => setShowKitConfig(!showKitConfig)}
                    handelSubmit={handleEditKitconfig} open={showKitConfig} />
            )}
            {
                showPrebuiltKitConfig && selectedConfig !== undefined && (
                    <PrebuiltKitConfigModal
                        preBuiltKitConfig={selectedPrebuiltConfig}
                        onClose={() => setShowPrebuiltKitConfig(!showPrebuiltKitConfig)}
                        handelSubmit={handleEditPreBuiltKitconfig} open={showPrebuiltKitConfig} />
                )
            }

        </div >
    )
}

export default PrebuiltKitConfigIndex;