import { Product } from "@medusajs/medusa"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../../../components/fundamentals/button"
import Modal from "../../../../../components/molecules/modal"
import { nestedForm } from "../../../../../utils/nested-form"
import DiscountableForm, {
  DiscountableFormType,
} from "../../../components/discountable-form"
import HiddenForm, { HiddenFormType } from "../../../components/metadata-form/metadata-hidden-form"
import KitConfigImageForm, {
  KitConfigImageFormType,
} from "../../../components/metadata-form/metadata-kit-config-image-form"
import KitConfigForm, {
  KitConfigFormFormType,
} from "../../../components/metadata-form/metadata-kit-config-form"
import SelfAsConfigForm, {
  SelfAsConfigFormType,
} from "../../../components/metadata-form/metadata-self-as-config-form"
import useEditProductActions from "../../hooks/use-edit-product-actions"
import useNotification from "../../../../../hooks/use-notification"
import { prepareImages } from "../../../../../utils/images"
import { FormImage } from "../../../../../types/shared"
import PhysicalProductForm, { PhysicalProductType } from "../../../components/metadata-form/metadata-physicalProduct-form"
import PhysicalProductIdForm, { PhysicalProductIdType } from "../../../components/metadata-form/metadata-physicalProductId-form"
import DigitalProductForm, { DigitalProductType } from "../../../components/metadata-form/metadata-digitalProduct-form"
import DigitalProductEmailIdForm, { DigitalProductEmailIdType } from "../../../components/metadata-form/metadata-digitalProductEmailId-form"
import ReservationProductForm, { ReservationProductType } from "../../../components/metadata-form/metadatal-reservation-Product-form"
import PreOrderIdForm, { PreOrderIdType } from "../../../components/metadata-form/metadata-preOrder-form"
import ArrayTextInput from "../../../components/metadata-form/metadata-array-text-form"
import UnitQtyForm, { UnitQtyType } from "../../../components/metadata-form/metadata-unitQty-form"
import PreBuiltKitConfigsForm, { PreBuiltKitConfigsType } from "../../../components/metadata-form/metadata-prebuiltKitconfig-form"




type Props = {
  product: Product
  open: boolean
  onClose: () => void
}
type KitConfigProducts = {
  product_id: string
  product_name: string
  qtyNeeded: Number
  preSelected: boolean
  subTitle: string
}
type KitConfigMetadata = {
  title: string
  mustSelect: boolean
  selectOne: boolean
  products: KitConfigProducts[]
}
type ProductMetadata = {
  unitQty: UnitQtyType
  digitalProduct: DigitalProductType,
  digitalProductEmailId: DigitalProductEmailIdType,
  physicalProductForReservation: PhysicalProductType,
  reservationProduct: ReservationProductType,
  preOrderId: PreOrderIdType
  physicalProductId: PhysicalProductIdType,
  hidden: HiddenFormType
  hoverImage: KitConfigImageFormType
  kitConfigImage: KitConfigImageFormType
  kitConfigMaterialImage: KitConfigImageFormType
  kitConfig: KitConfigFormFormType
  selfAsConfig: SelfAsConfigFormType
  features: { features: string[] }
  specifications: { specifications: string[] }

  docs: { docs: string[] }
  preBuiltKitConfigs: PreBuiltKitConfigsType


}

type KitConfigForm = {
  metadata: ProductMetadata

}

const KitConfigModal = ({ product, open, onClose }: Props) => {
  const { onUpdate, updating } = useEditProductActions(product.id)
  const form = useForm<KitConfigForm>({
    defaultValues: getDefaultValues(product),
  })
  const notification = useNotification()

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form

  useEffect(() => {
    reset(getDefaultValues(product))
  }, [product])

  const onReset = () => {
    reset(getDefaultValues(product))
    onClose()
  }

  const onSubmit = handleSubmit(async (data) => {
    console.log("data", data)
    let preppedHoverImages: FormImage[] = []
    let preppedKitConfigImages: FormImage[] = []
    let preppedKitConfigMaterialImages: FormImage[] = []

    try {
      preppedKitConfigImages = await prepareImages(data.metadata.kitConfigImage.images)
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the KitConfigImages ."
      const response = (error as any).response as Response
      if (response.status === 500) {
        errorMessage =
          errorMessage +
          " " +
          "You might not have a file service configured. Please contact your administrator"
      }
      notification("Error", errorMessage, "error")
      return
    }
    try {
      preppedKitConfigMaterialImages = await prepareImages(data.metadata.kitConfigMaterialImage.images)
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the KitConfigMaterialImages."
      const response = (error as any).response as Response
      if (response.status === 500) {
        errorMessage =
          errorMessage +
          " " +
          "You might not have a file service configured. Please contact your administrator"
      }
      notification("Error", errorMessage, "error")
      return
    }
    try {
      preppedHoverImages = await prepareImages(data.metadata.hoverImage.images)
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the hover image."
      const response = (error as any).response as Response
      if (response.status === 500) {
        errorMessage =
          errorMessage +
          " " +
          "You might not have a file service configured. Please contact your administrator"
      }
      notification("Error", errorMessage, "error")
      return
    }
    const hoverImageUrl = preppedHoverImages?.[0]?.url
    const kitConfigImageUrl = preppedKitConfigImages?.[0]?.url
    const kitConfigMaterialImageUrl = preppedKitConfigMaterialImages?.[0]?.url


    let updateData = {
      physicalProductForReservation: data.metadata.physicalProductForReservation.physicalProductForReservation,
      digitalProductEmailId: data.metadata.digitalProductEmailId.digitalProductEmailId,
      digitalProduct: data.metadata.digitalProduct.digitalProduct,
      physicalProductId: data.metadata.physicalProductId.physicalProductId,
      preOrderId: data.metadata.preOrderId.preOrderId,
      reservationProduct: data.metadata.reservationProduct.reservationProduct,
      hidden: data.metadata.hidden.hidden,
      kitConfig: [],
      features: data.metadata.features.features,
      specifications: data.metadata.specifications.specifications,
      docs: data.metadata.docs.docs,
      unitQty: data.metadata.unitQty.unitQty,
      selfAsConfig: data.metadata.selfAsConfig,
      preBuiltKitConfigs: data.metadata.preBuiltKitConfigs.preBuiltKitConfigs.map((preBuiltKitConfig) => ({ title: preBuiltKitConfig.label, id: preBuiltKitConfig.value }))
    }
    if (data.metadata.kitConfig) {
      //@ts-ignore
      updateData.kitConfig = data.metadata.kitConfig.map(kitConfig => ({
        ...kitConfig
        , products: kitConfig.products.map(product => ({
          ...product,
          product_id: product.product_id.value,
          product_name: product.product_id.label
        }))
      }))
    }
    if (kitConfigImageUrl !== null) {
      //@ts-ignore
      updateData.kitConfigImage = kitConfigImageUrl
    }
    if (hoverImageUrl !== null) {
      //@ts-ignore
      updateData.hoverImage = hoverImageUrl
    }
    if (kitConfigMaterialImageUrl !== null) {
      //@ts-ignore
      updateData.kitConfigMaterialImage = kitConfigMaterialImageUrl
    }

    if (data.metadata.kitConfigMaterialImage.images.length === 0) {
      //@ts-ignore
      updateData.kitConfigMaterialImage = null
    }

    if (data.metadata.kitConfigImage.images.length === 0) {
      //@ts-ignore
      updateData.kitConfigImage = null
    }

    if (data.metadata.hoverImage.images.length === 0) {
      //@ts-ignore
      updateData.hoverImage = null
    }

    onUpdate(
      { metadata: updateData },
      onReset
    )
  })

  return (
    <Modal open={open} handleClose={onReset} isLargeModal>
      <Modal.Body>
        <Modal.Header handleClose={onReset}>
          <h1 className="inter-xlarge-semibold m-0">
            Edit Config Information
          </h1>
        </Modal.Header>
        <form onSubmit={onSubmit}>
          <Modal.Content>
            <div className="my-xlarge">

              <h2 className="inter-base-semibold mb-base">Product Info</h2>

              <h2 className="inter-base-semibold mb-base">features</h2>
              <ArrayTextInput form={nestedForm(form, "metadata.features")} nested={"features"} />
              <h2 className="inter-base-semibold mb-base">specifications</h2>
              <ArrayTextInput form={nestedForm(form, "metadata.specifications")} nested={"specifications"} />
              <h2 className="inter-base-semibold mb-base">docs</h2>
              <ArrayTextInput form={nestedForm(form, "metadata.docs")} nested={"docs"} />
              <hr className="mt-5 mb-5" />
              <h2 className="inter-base-semibold mb-base">Hidden</h2>

              <HiddenForm form={nestedForm(form, "metadata.hidden")} />

              <h2 className="inter-base-semibold mb-base">Qty</h2>

              <UnitQtyForm form={nestedForm(form, "metadata.unitQty")} />


              <hr className="mt-5 mb-5" />
              <h2 className="inter-base-semibold mb-base">Images</h2>

              <h2 className="inter-base-semibold mb-base">Hover Image</h2>

              <KitConfigImageForm form={nestedForm(form, "metadata.hoverImage")} />

              <h2 className="inter-base-semibold mb-base">kit config Image</h2>

              <KitConfigImageForm form={nestedForm(form, "metadata.kitConfigImage")} />
              <h2 className="inter-base-semibold mb-base">kit config Material Image</h2>

              <KitConfigImageForm form={nestedForm(form, "metadata.kitConfigMaterialImage")} />
              <SelfAsConfigForm form={nestedForm(form, "metadata.selfAsConfig")} />
              <h2 className="inter-base-semibold mb-base text-xl">kit config</h2>
              <PreBuiltKitConfigsForm
                productId={product.id}
                form={nestedForm(form, "metadata.preBuiltKitConfigs")}

              />
              <KitConfigForm
                form={nestedForm(form, "metadata.kitConfig")}
                // kitConfig={options}
                onCreatekitConfig={(e) => console.log("e create", e)}
              />
              {/* <OrganizeForm form={nestedForm(form, "organize")} /> */}

              <hr className="mt-5 mb-5" />

              <DigitalProductForm form={nestedForm(form, "metadata.digitalProduct")} />
              <DigitalProductEmailIdForm form={nestedForm(form, "metadata.digitalProductEmailId")} />
              <hr className="mt-5 mb-5" />
              <h2 className="inter-base-semibold mb-base">Pre Order Stuff</h2>
              <PhysicalProductForm form={nestedForm(form, "metadata.physicalProductForReservation")} />
              <ReservationProductForm form={nestedForm(form, "metadata.reservationProduct")} />

              <PhysicalProductIdForm form={nestedForm(form, "metadata.physicalProductId")} />
              <PreOrderIdForm form={nestedForm(form, "metadata.preOrderId")} />

            </div>

          </Modal.Content>
          <Modal.Footer>
            <div className="flex gap-x-2 justify-end w-full">
              <Button
                size="small"
                variant="secondary"
                type="button"
                onClick={onReset}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="primary"
                type="submit"
                disabled={!isDirty}
                loading={updating}
              >
                Save
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  )
}

const getDefaultValues = (product: Product): KitConfigForm => {
  const mData = product.metadata
  return {
    metadata: {
      features: { features: mData?.features && Array.isArray(mData?.features) ? mData?.features : [] },
      specifications: { specifications: mData?.specifications && Array.isArray(mData?.specifications) ? mData?.specifications : [] },
      docs: { docs: mData?.docs && Array.isArray(mData?.docs) ? mData?.docs : [] },

      digitalProductEmailId: { digitalProductEmailId: mData?.digitalProductEmailId && typeof mData?.digitalProductEmailId === "string" ? mData?.digitalProductEmailId : "" },
      digitalProduct: { digitalProduct: mData?.digitalProduct == undefined ? false : typeof mData?.digitalProduct === "boolean" ? mData.digitalProduct : false },
      physicalProductId: { physicalProductId: mData?.physicalProductId && typeof mData?.physicalProductId === "string" ? mData?.physicalProductId : "" },
      physicalProductForReservation: { physicalProductForReservation: mData?.physicalProductForReservation == undefined ? false : typeof mData?.physicalProductForReservation === "boolean" ? mData.physicalProductForReservation : false },
      preOrderId: { preOrderId: mData?.preOrderId && typeof mData?.preOrderId === "string" ? mData?.preOrderId : "" },
      reservationProduct: { reservationProduct: mData?.reservationProduct == undefined ? false : typeof mData?.reservationProduct === "boolean" ? mData.reservationProduct : false },
      hidden: { hidden: mData?.hidden == undefined ? false : typeof mData?.hidden === "boolean" ? mData.hidden : false },
      unitQty: { unitQty: mData?.unitQty !== undefined && typeof mData?.unitQty === "number" ? mData?.unitQty : 1 },
      preBuiltKitConfigs: {
        preBuiltKitConfigs: mData?.preBuiltKitConfigs !== undefined && Array.isArray(mData?.preBuiltKitConfigs) ? mData?.preBuiltKitConfigs.map(prebuilt => ({
          value: prebuilt.id as string, label: prebuilt.title as string,
        })) : []
      },

      kitConfigImage: {
        //@ts-ignore
        images: mData?.kitConfigImage !== undefined
          ? [
            {
              url: mData.kitConfigImage
            },
          ]
          : [],
      },

      hoverImage: {
        //@ts-ignore
        images: mData?.hoverImage !== undefined
          ? [
            {
              url: mData.hoverImage
            },
          ]
          : [],
      },
      kitConfigMaterialImage: {
        //@ts-ignore
        images: mData?.kitConfigMaterialImage !== undefined
          ? [
            {
              url: mData.kitConfigMaterialImage
            },
          ]
          : [],
      },
      //@ts-ignore
      selfAsConfig: mData?.selfAsConfig !== undefined ? { ...mData.selfAsConfig } : {
        selfAsConfig: false,
        qtyNeeded: 1,
        preSelected: false,
        subTitle: "",
        title: "",
        mustSelect: false,
        selectOne: false
      },
      //@ts-ignore
      kitConfig: mData?.kitConfig == undefined ? [] : mData.kitConfig.map(kitConfig => ({
        ...kitConfig
        , products: kitConfig.products.map(product => ({
          ...product,
          product_id: { label: product.product_name, value: product.product_id }
        }))
      }))
    }
  }
}

export default KitConfigModal
