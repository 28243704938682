
import React, { useEffect, useState } from "react"
import Button from "../../../../../components/fundamentals/button"
import Modal from "../../../../../components/molecules/modal"
import { CreatePreOrderInput, PreOrder } from "../../../../../types/shared"
import PreOrderForm from "../preOrderForm"
import Medusa from "../../../../../services/api"
import Input from "../../../../../components/molecules/input"
import { useToasts } from "react-toast-notifications"
import EmailTemplateHover from "../../../../../components/molecules/email-template-hover"



type Props = {
    preOrder?: PreOrder
    open: boolean
    onClose: () => void
}


const PreOrderEditModal = ({ preOrder, open, onClose }: Props) => {
    const [emailTemplate, setEmailTemplate] = useState("")
    const { addToast } = useToasts();

    const onSubmit = (preOrderUpdate: PreOrder | CreatePreOrderInput) => {
        Medusa.customRoutes.updatePreOrder(preOrder?.id, { ...preOrderUpdate }).then(() => {
            onClose()
        })

    }


    const onReset = () => {
        onClose()
    }

    const onDelete = () => {
        Medusa.customRoutes.deletePreOrder(preOrder?.id).then(() => {
            onClose()
        })
    }
    const sendEmail = () => {
        if (emailTemplate === "" || !emailTemplate.startsWith("d-")) {
            addToast("need a valid emailTemplate", { appearance: 'error' });
            return
        }
        Medusa.customRoutes.emailPreOrder(preOrder?.id, emailTemplate).then(res => {
            addToast(`sent that off to ${res.data.count} orders`, { appearance: 'success' })
        })
    }


    return (
        <Modal open={open} handleClose={onReset} isLargeModal>
            <Modal.Body>
                <Modal.Header handleClose={onReset}>
                    <h1 className="inter-xlarge-semibold m-0">Edit Top Bar</h1>
                </Modal.Header>

                <Modal.Content>
                    <div className="mb-xlarge">
                        <PreOrderForm onSubmit={onSubmit} preOrder={preOrder} />

                    </div>
                    <div className="mb-xlarge">
                        <EmailTemplateHover>
                            <h3>
                                send email template to this preOrder
                            </h3>
                        </EmailTemplateHover>
                        <Input
                            value={emailTemplate}
                            onChange={(e) => setEmailTemplate(e.target.value)}
                            placeholder="d-122333ABC"
                        />
                        <Button
                            size="small"
                            variant="secondary"
                            type="button"
                            onClick={sendEmail}>
                            send
                        </Button>
                    </div>

                </Modal.Content>
                <Modal.Footer>
                    <div className="flex gap-x-2 justify-end w-full">
                        <Button
                            size="small"
                            variant="secondary"
                            type="button"
                            onClick={onReset}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="secondary"
                            type="button"
                            onClick={onDelete}
                        >
                            delete
                        </Button>

                    </div>
                </Modal.Footer>

            </Modal.Body>
        </Modal>
    )
}

export default PreOrderEditModal
