import { Product, ProductTag } from "@medusajs/medusa"
import { Switch } from "@radix-ui/react-switch"
import React, { useEffect, useState } from "react"
import { Controller, FieldArrayWithId, useForm } from "react-hook-form"
import Button from "../../../../../components/fundamentals/button"
import Modal from "../../../../../components/molecules/modal"
import { CreateTopBarInput, TopBar } from "../../../../../types/shared"
import TopBarForm from "../topBarForm"
import Medusa from "../../../../../services/api"



type Props = {
    topBar?: TopBar
    open: boolean
    onClose: () => void
}


const TopBarEditModal = ({ topBar, open, onClose }: Props) => {
    const onSubmit = (topBarUpdate: TopBar | CreateTopBarInput) => {
        Medusa.customRoutes.updateTopBar(topBar?.id, { ...topBarUpdate }).then(() => {
            onClose()
        })

    }


    const onReset = () => {
        onClose()
    }

    const onDelete = () => {
        Medusa.customRoutes.deleteTopBar(topBar?.id).then(() => {
            onClose()
        })

    }


    return (
        <Modal open={open} handleClose={onReset} isLargeModal>
            <Modal.Body>
                <Modal.Header handleClose={onReset}>
                    <h1 className="inter-xlarge-semibold m-0">Edit Top Bar</h1>
                </Modal.Header>

                <Modal.Content>
                    <div className="mb-xlarge">
                        <TopBarForm onSubmit={onSubmit} topBar={topBar} />

                    </div>

                </Modal.Content>
                <Modal.Footer>
                    <div className="flex gap-x-2 justify-end w-full">
                        <Button
                            size="small"
                            variant="secondary"
                            type="button"
                            onClick={onReset}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="secondary"
                            type="button"
                            onClick={onDelete}
                        >
                            delete
                        </Button>

                    </div>
                </Modal.Footer>

            </Modal.Body>
        </Modal>
    )
}

export default TopBarEditModal
