import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Controller } from "react-hook-form"
import { NestedForm } from "../../../../../utils/nested-form"
import InputField from "../../../../../components/molecules/input";
import Button from "../../../../../components/fundamentals/button";
import TrashIcon from "../../../../../components/fundamentals/icons/trash-icon";



type Props = {
    form: NestedForm<any>
    nested: string
}


const ArrayTextInput = ({ form, nested }: Props) => {
    const { register, control, path } = form

    const { fields, append, remove } = useFieldArray({
        name: nested ? `${path()}.${nested}` : path(),
        control: form.control,
        shouldUnregister: true,
    });

    return (
        <div className="flex flex-col">
            <ul className="mb-4">
                {fields.map((item, index) => (
                    <li key={item.id} className="flex items-end">
                        <InputField
                            label={`${index}:`}
                            {...register(path(`${nested}.${index}`), {
                            })}
                        />
                        <Button
                            variant="secondary"
                            className=" ml-4 px-2.5 py-2.5 max-h-[40px]"
                            type="button"
                            onClick={() => remove(index)}
                        >
                            <TrashIcon className="text-grey-40" size="20" />
                        </Button>
                    </li>
                ))}
            </ul>
            <Button
                type="button"
                onClick={() => append("")} variant={"primary"}            >
                append
            </Button>
        </div>
    );
}
export default ArrayTextInput