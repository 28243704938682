import { Product } from "@medusajs/medusa"
import React, { useEffect, useState } from "react"
import Section from "../../../../../components/organisms/section"
import api from "../../../../../services/api"
import Graphs from "../../../../dashboard/graphs"
import { DashboardGraphData } from "../../../../../types/shared"
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from "recharts"
import { StatRow, BasicStat } from "../../../../../components/organisms/display-stat"
import { keys } from "lodash"

type Props = {
    product: Product
}

const DataSection = ({ product }: Props) => {
    const colors = ["#8884d8", "#D884AA", "#D4D884", "#84D8B2", "#84D884", "#D88484"]
    const [salesData, setSalesData] = useState<DashboardGraphData>(),
        [dataKeys, setDataKeys] = useState<string[]>()
    const getKeys = (salesData: DashboardGraphData) => {
        let keys: string[] = []
        if (salesData) {
            salesData.salesByDay.forEach(day => {
                Object.keys(day).forEach(key => {
                    keys.push(key)
                })
            });
        }
        keys = Array.from(new Set(keys)).filter(key => key !== "date" && key !== "total")
        setDataKeys(keys)
    }
    const fetchData = async () => {
        try {
            const salesData = await api.customRoutes.getProductSalesData(product.id)
            console.log("the data says", salesData.data)
            getKeys(salesData.data)
            setSalesData(salesData.data)


        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        fetchData()
    }, [product])

    const getAvgSoldPerDay = () => {
        if (salesData) {
            const firstSold = salesData.salesByDay.findIndex(day => day.total > 0)
            const salesRange = salesData.salesByDay.slice(firstSold)
            return salesRange.reduce((acc, cur) => {
                return acc + cur.total
            }, 0) / salesRange.length
        }
        return 0
    }
    const getTopSellingColor = () => {
        if (salesData) {
            let emptyData = {}
            dataKeys?.forEach(key => {
                emptyData[key] = 0
            })

            const salesForEach = salesData.salesByDay.reduce((acc, cur) => {
                let tempAcc = { ...acc }
                dataKeys?.forEach(key => {
                    tempAcc[key] += cur[key]
                })
                return tempAcc
            }, emptyData)
            let highest = { color: "", value: 0 }
            Object.keys(salesForEach).forEach(key => {
                if (salesForEach[key] > highest.value) {
                    highest.color = key
                    highest.value = salesForEach[key]
                }
            })
            return highest.color

        }
        return "black 😜"
    }
    return (
        <>
            <Section title="Data" forceDropdown>
                <div className="grid gap-4 grid-cols-12 grid-rows-2">
                    {salesData ? (<>
                        <div className="row-start-1 row-end-3 flex col-span-2">
                            <StatRow vertical>
                                <BasicStat stat={getAvgSoldPerDay()} displayAsCurrency={false} title="avg.# per day" />
                                <BasicStat stat={getTopSellingColor()} displayAsCurrency={true} title="best color" />
                            </StatRow>
                        </div>
                        <div className="row-start-1 row-end-3 flex col-span-10 min-h-[300px]">
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    width={800}
                                    height={300}
                                    data={salesData.salesByDay}

                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    {dataKeys?.map((key, index) => {
                                        return <Line type="monotone" dataKey={key} stroke={colors[index]} activeDot={{ r: 8 }} />
                                    })}
                                    {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                                </LineChart>
                            </ResponsiveContainer>

                        </div>
                    </>
                    ) : "loading"}
                </div>


            </Section>


        </>
    )
}

export default DataSection
