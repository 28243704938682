import React, { useEffect, useMemo, useState } from "react"


import Medusa from "../../../services/api"

import { KitConfigProduct } from "../../../types/shared"
import SimpleTable from "../../../domain/dashboard/table"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"
import KitConfigProductModal from "./kit-config-product/modal"



const KitconfigProductIndex = () => {
    const notification = useNotification()

    const [showKitConfigProduct, setShowKitConfigProduct] = useState(false),
        [selectedproduct, setSelectedproduct] = useState<KitConfigProduct | undefined>()
    const getKitconfigProducts = async () => {
        const res = await Medusa.customRoutes.getKitConfigProducts()
        setKitconfigProducts(res.data)

    }
    const handleEditKitconfigProduct = async (data) => {
        try {
            if (selectedproduct) {
                const res = await Medusa.customRoutes.updateKitConfigProduct(selectedproduct.id, data)
                setShowKitConfigProduct(false)
                setSelectedproduct(undefined)
                notification("Success", "Successfully created KitConfigProduct", "success")
                getKitconfigProducts()

            } else {
                console.log("no product selected")
            }

        } catch (error) {
            notification("Error", getErrorMessage(error), "error")
            console.log(error)
        }
    }
    const handleDeleteKitconfigProduct = async (id: string) => {
        try {

            const res = await Medusa.customRoutes.deleteKitConfigProduct(id)

            notification("Success", "Successfully Deleted KitConfigProduct", "success")
            getKitconfigProducts()


        } catch (error) {
            notification("Error", getErrorMessage(error), "error")
            console.log(error)
        }
    }



    const [kitconfigProducts, setKitconfigProducts] = useState<KitConfigProduct[] | undefined>()
    useEffect(() => {

        if (kitconfigProducts === undefined) {
            getKitconfigProducts()
        }
    }, [kitconfigProducts])

    return (
        <div className="flex flex-col grow h-full">
            <div className="w-full flex flex-col grow">

                {kitconfigProducts !== undefined ? (

                    <SimpleTable
                        title={"Kitconfig Products"}
                        className="mb-4"
                        noMaxHeight
                        heads={["product", "config product name", "subtitle", "quantity", "pre selected", "delete"]}>
                        {kitconfigProducts.map((kitconfigProduct) => (

                            <tr onClick={() => {
                                setShowKitConfigProduct(true)
                                setSelectedproduct(kitconfigProduct)
                            }} key={kitconfigProduct.id}>
                                <th>

                                    {kitconfigProduct.product.title}

                                </th>
                                <td>

                                    {kitconfigProduct.product_name}

                                </td>
                                <td>
                                    {kitconfigProduct.subTitle}
                                </td>
                                <td>
                                    {kitconfigProduct.qtyNeeded}
                                </td>
                                <td>
                                    {kitconfigProduct.preSelected ? "✅" : "❌"}
                                </td>
                                {/* <td>
                                    <button className="btn btn-error" onClick={(e) => {
                                        e.stopPropagation()
                                        handleDeleteKitconfigProduct(kitconfigProduct.id)
                                    }}>
                                        Delete
                                    </button>
                                </td> */}

                            </tr>

                        ))}
                    </SimpleTable>) :
                    ("loading")
                }


            </div>
            {showKitConfigProduct && selectedproduct !== undefined && (
                <KitConfigProductModal
                    kitconfigProduct={selectedproduct}
                    onClose={() => setShowKitConfigProduct(!showKitConfigProduct)}
                    handelSubmit={handleEditKitconfigProduct} open={showKitConfigProduct} />
            )}

        </div>
    )
}

export default KitconfigProductIndex