import React, { useState } from "react"
import Button from "../../fundamentals/button"
import Modal from "../../molecules/modal"

type ExportModalProps = {
  handleClose: () => void
  onSubmit?: () => void
  loading: boolean
  title: string
}

import Medusa from "../../../services/api"

const ExportModal: React.FC<ExportModalProps> = ({
  handleClose,
  title,
  loading,
  onSubmit,
}) => {
  const [metadata, setMetadata] = useState("Click Export")
  const fetchData = async () => {
    try {
      const data = await Medusa.customRoutes.getMassMetadataExport()
      const newData = JSON.stringify(data.data.data, null, 2)
      setMetadata(newData)
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <Modal handleClose={handleClose}>
      <Modal.Body>
        <Modal.Header handleClose={handleClose}>
          <span className="inter-xlarge-semibold">{title}</span>
        </Modal.Header>
        <Modal.Content>

          <div className="flex mb-4 inter-small-regular text-grey-50">
            Initialize an export of your data
          </div>
          <div className="">
            <h3 className="text-lg">metadata export</h3>
            <br />

            <textarea className="i bg-white text-black w-full h-60" value={metadata}>
            </textarea>

          </div>

        </Modal.Content>
        <Modal.Footer>
          <div className="w-full flex justify-end">
            <Button
              loading={loading}
              variant="primary"
              size="small"
              onClick={fetchData}
            >
              Export MetaData
            </Button>
            <Button
              variant="ghost"
              size="small"
              onClick={handleClose}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              variant="primary"
              size="small"
              onClick={onSubmit}
            >
              Export
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default ExportModal
