import { RouteComponentProps } from "@reach/router"
import React, { useEffect, useState } from "react"
import BackButton from "../../../components/atoms/back-button"
import Spinner from "../../../components/atoms/spinner"
import Button from "../../../components/fundamentals/button"
import Section from "../../../components/organisms/section"
import useToggleState from "../../../hooks/use-toggle-state"
import Medusa from "../../../services/api"
import { CreatePreOrderInput, PreOrder } from "../../../types/shared"
import PreOrderView from "./components/preOrder"
import PreOrderEditModal from "./components/preOrderEditModal"
import PreOrderNewModal from "./components/preOrderNewModal"


const PreOrderSettings = (_props: RouteComponentProps) => {

  const [preOrders, setPreOrders] = useState<PreOrder[]>([]),
    [currentPreOrder, setCurrentPreOrder] = useState<PreOrder>(),
    { state: newstate, toggle: newToggle, close: newClose } = useToggleState(),
    { state: editstate, toggle: editToggle, close: editClose } = useToggleState()

  const onClose = () => {
    newClose()
    editClose()
    Medusa.customRoutes.getPreOrders().then(res => {
      setPreOrders(res.data)
    })
  }
  useEffect(() => {
    if (preOrders.length == 0) {
      Medusa.customRoutes.getPreOrders().then(res => {
        setPreOrders(res.data)
      })
    }
  })
  const onPreOrderClick = (preOrder: PreOrder) => {
    setCurrentPreOrder(preOrder)
    editToggle()
  }
  if (preOrders.length > 0) {
    return (
      <div className="pb-xlarge">
        <BackButton
          label="Back to Settings"
          path="/a/settings"
          className="mb-xsmall"
        />
        <div className="grid grid-cols-3 gap-base">
          <div className="col-span-2 flex flex-col gap-y-xsmall ">
            <Section title="Pre-order">
              <p className="text-grey-50 inter-base-regular mt-2xsmall">
                Manage the Pre-orders.
              </p>
              <Button variant={"primary"} onClick={() => newToggle()}>New Pre-order</Button>
            </Section>

            <Section>
              <h3>
                All Pre-orders *click to edit
              </h3>

              {preOrders.map(preOrder => (<PreOrderView preOrder={preOrder} onClick={onPreOrderClick} />))}
            </Section>
          </div>
          <div>
            <Section>
              <h3>
                Active Pre-Orders
              </h3>
              {preOrders
                .filter(preOrder => !preOrder.finished)
                .map(preOrder => (<PreOrderView preOrder={preOrder} onClick={onPreOrderClick} />))}
            </Section>
          </div>
        </div>
        <PreOrderNewModal open={newstate} onClose={onClose} />
        <PreOrderEditModal preOrder={currentPreOrder} onClose={onClose} open={editstate} />
      </div>
    )
  }
  return (
    <div className="pb-xlarge">
      <BackButton
        label="Back to Settings"
        path="/a/settings"
        className="mb-xsmall"
      />
      <div className="grid grid-cols-3 gap-base">
        <div className="col-span-2 flex flex-col gap-y-xsmall ">
          <Section title="Pre-order">
            <p className="text-grey-50 inter-base-regular mt-2xsmall">
              Manage the preorders.
            </p>
            <Button variant={"primary"} onClick={() => newToggle()}>New Preorder</Button>
          </Section>

          <Section>
            <Spinner withText />
          </Section>
        </div>
        <div>
          <Section>
            <Spinner withText />
          </Section>
        </div>
        <PreOrderNewModal open={newstate} onClose={onClose} />

      </div>

    </div>
  )
}

export default PreOrderSettings
