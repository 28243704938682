
import React from "react"
import { Controller } from "react-hook-form"
import Switch from "../../../../../components/atoms/switch"
import IndeterminateCheckbox from "../../../../../components/molecules/indeterminate-checkbox"
import { NextSelect } from "../../../../../components/molecules/select/next-select"
import { NestedForm } from "../../../../../utils/nested-form"

export type DigitalProductType = {
    digitalProduct: boolean

}

type DigitalProductFormProps = {
    form: NestedForm<DigitalProductType>
}

/**
 * Re-usable nested form used to submit customs information for products and their variants.
 * @example
 * <HiddenForm form={nestedForm(form, "hidden")} />
 */
const DigitalProductForm = ({ form }: DigitalProductFormProps) => {
    const {
        register,
        path,
        control,
        formState: { errors },
    } = form


    return (
        <div>
            <div className="flex items-center justify-between mb-2xsmall">
                <h2 className="inter-base-semibold">Digital Product</h2>
                <Controller
                    control={control}
                    name={path("digitalProduct")}
                    render={({ field: { value, onChange } }) => {
                        return <Switch checked={value} onCheckedChange={onChange} />
                    }}
                />
            </div>
            <p className="inter-base-regular text-grey-50">
                When checked this product is a digital Product and needs a email template, and will only be able to be fufilled with a email.
            </p>
        </div>
    )
}

export default DigitalProductForm

