import React, { useEffect, useState } from "react"

import Button from "../../fundamentals/button"
import Modal from "../../molecules/modal"
import { CreatePreBuiltKitConfigInput, KitConfig, KitConfigProduct, PreBuiltKitConfig } from "../../../types/shared"
import { Controller, useForm } from "react-hook-form"
import InputField from "../../molecules/input"

import Medusa from "../../../services/api"
import SortableMultiSelect from "../../atoms/sortable-multi-select";


type Props = {
    preBuiltKitConfig?: PreBuiltKitConfig
    open: boolean
    onClose: () => void
    handelSubmit: (kitconfig: CreatePreBuiltKitConfigInput) => void
}

type CreatePreBuiltKitConfigInputFourm = Omit<CreatePreBuiltKitConfigInput, "kitConfig"> & { kitConfig: { value: string; label: string }[] }


const PrebuiltKitConfigModal = ({ preBuiltKitConfig, open, onClose, handelSubmit }: Props) => {
    const [kitconfigs, setKitconfigs] = useState<KitConfig[] | undefined>()

    const kitconfigOptions = kitconfigs === undefined ? [] : kitconfigs.map((kitconfig) => ({
        label: `${kitconfig.title}: ${kitconfig.kitConfigProducts.map(p => p.product_name).join(", ")}`,
        value: kitconfig.id
    }))

    const { register, handleSubmit, watch, reset, formState: { errors, isDirty }, control } =

        //@ts-ignore
        useForm<CreatePreBuiltKitConfigInputFourm>({ defaultValues: getDefaultValues(preBuiltKitConfig) });

    const onSubmit = (data) => {
        let kitconfig: CreatePreBuiltKitConfigInput = {
            title: data.title,
            description: data.description,
            kitConfig: data.kitConfig.map((kitconfig) => kitconfig.value)


        }
        console.log(kitconfig)
        handelSubmit({ ...kitconfig })

    }



    useEffect(() => {
        const getKitconfigProducts = async () => {
            const res = await Medusa.customRoutes.getKitConfigs()
            setKitconfigs(res.data)

        }
        if (kitconfigs === undefined) {
            getKitconfigProducts()
        }
        //@ts-ignore

        // reset(getDefaultValues(kitconfig))
    }, [preBuiltKitConfig, kitconfigs])




    return (
        <Modal open={open} handleClose={onClose} isLargeModal>
            <Modal.Body>
                <Modal.Header handleClose={onClose}>
                    <h1 className="inter-xlarge-semibold m-0">
                        Pre-built Kit Config
                    </h1>
                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <Modal.Content>


                        <p className="inter-small-semibold text-grey-50">Title</p>

                        <Controller
                            control={control}
                            name={`title`}
                            render={({ field: { value, onChange } }) => {
                                return <InputField
                                    placeholder="Ble Corne Config"
                                    onChange={onChange}
                                    value={value}
                                />
                            }}
                        />
                        <p className="inter-small-semibold text-grey-50">Description</p>

                        <Controller
                            control={control}
                            name={`description`}
                            render={({ field: { value, onChange } }) => {
                                return <InputField
                                    placeholder="Everything pre selected for BLE MX Corne kit"
                                    onChange={onChange}
                                    value={value}
                                />
                            }}
                        />



                        <div
                            className=" gap-x-xsmall mt-2 rounded-rounded m-2xsmall"
                        >
                            <h2 className="inter-small-semibold text-grey-50">
                                Kit configs
                            </h2>
                            <Controller
                                control={control}
                                name={`kitConfig`}
                                render={({ field }) => {
                                    return (
                                        <SortableMultiSelect
                                            // label="Product option"
                                            // placeholder="Choose a product"
                                            options={kitconfigOptions}
                                            // isMultiSelect
                                            //@ts-ignore
                                            // isSearchable
                                            // isClearable
                                            value={field.value}
                                            onChange={field.onChange}

                                        />
                                    )
                                }}
                            />

                        </div>




                    </Modal.Content>
                    <Modal.Footer>
                        <div className="flex gap-x-2 justify-end w-full">
                            <Button
                                size="small"
                                variant="secondary"
                                type="button"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="secondary"
                                type="submit"

                            >
                                Save
                            </Button>

                        </div>
                    </Modal.Footer>
                </form>

            </Modal.Body>
        </Modal>
    )
}



const getDefaultValues = (preBuiltKitConfig: PreBuiltKitConfig | undefined) => {
    let emptyPreBuiltKitConfig: CreatePreBuiltKitConfigInput = {
        title: "",
        description: "",
        kitConfig: []
    }

    if (preBuiltKitConfig) {
        for (const [key, value] of Object.entries(preBuiltKitConfig)) {
            if (value && value !== undefined) {
                if (key === "kitConfig") {
                    //@ts-ignore
                    emptyPreBuiltKitConfig.kitConfig = preBuiltKitConfig.kitConfig.map((kitConfig) => ({ value: kitConfig.id, label: kitConfig.title }))
                }
                else {
                    emptyPreBuiltKitConfig[key] = value

                }
            }

        }

    }

    return emptyPreBuiltKitConfig
}


export default PrebuiltKitConfigModal
