import React from "react"

const EmailTemplateHover = ({ children }) => {
    return (<div className="flex">
        <div className="tooltip tooltip-info mr-2 tooltip-right" data-tip="sendgrind template id https://mc.sendgrid.com/dynamic-templates">
            hover *
        </div>
        {children}
    </div>
    )
}
export default EmailTemplateHover