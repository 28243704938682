import Switch from "../../../atoms/switch";

import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../molecules/input";
import { NextSelect } from "../../../molecules/select/next-select";
import control from "../../../molecules/select/next-select/components/control";
import { CreateKitConfigProductInput, KitConfigProduct, TopBar } from "../../../../types/shared";
import { useAdminProducts } from "medusa-react"
import Button from "../../../fundamentals/button";

type Props = {
    kitconfigProduct?: KitConfigProduct
    propsOnSubmit: (kitconfigProduct: CreateKitConfigProductInput) => void
}

type CreateKitConfigProductInputForm = {
    product_id: string
    product_name: string
    qtyNeeded: number
    preSelected: boolean
    subTitle: string
    id: string
    metadata: Record<string, unknown> | null

}

const KitConfigProductForm = ({ kitconfigProduct, propsOnSubmit }: Props) => {
    const { products, isLoading, isRefetching, count } = useAdminProducts({ limit: 500 })


    const productOptions = products === undefined ? [] : products.map((product) => ({
        label: product.title ? product.title : "",
        value: product.id ? product.id : "",
    }))
    const { register, handleSubmit, watch, reset, formState: { errors, isDirty }, control } =
        //@ts-ignore

        useForm<CreateKitConfigProductInputForm>({ defaultValues: getDefaultValues(kitconfigProduct) });
    const onSubmit = (data: CreateKitConfigProductInputForm) => {
        let valueTopBar: CreateKitConfigProductInput = {
            //@ts-ignore
            product_id: data.product_id.value,
            //@ts-ignore
            product_name: data.product_name,
            qtyNeeded: data.qtyNeeded,
            preSelected: data.preSelected,
            subTitle: data.subTitle
        }
        propsOnSubmit({ ...valueTopBar })

    }

    useEffect(() => {
        //@ts-ignore

        reset(getDefaultValues(kitconfigProduct))
    }, [kitconfigProduct])



    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <div
                className="">

                <p className="inter-small-semibold text-grey-50">kit config product name</p>
                <p className="inter-small text-grey-40">for internal use eg (split 2x blok) vs (blok) that would only include 1</p>

                <Controller
                    control={control}
                    name={`product_name`}
                    render={({ field: { value, onChange } }) => {
                        return <InputField

                            placeholder="kit config product name"
                            onChange={onChange}
                            value={value}
                        />
                    }}
                />
                <p className="inter-small-semibold text-grey-50">product Subtitle</p>
                <Controller
                    control={control}
                    name={`subTitle`}
                    render={({ field: { value, onChange } }) => {
                        return <InputField
                            placeholder="Subtitle"
                            onChange={onChange}
                            value={value}
                        />
                    }}
                />

                <Controller
                    control={control}
                    name={`qtyNeeded`}
                    render={({ field: { value, onChange } }) => {
                        return <InputField
                            label="qty needed"
                            placeholder="10..."
                            type="number"
                            onChange={onChange}
                            value={`${value}`}
                        />
                    }}
                />


                <div className="flex w-full">

                    <div className="flex w-full items-center justify-between mb-2xsmall">
                        <h2 className="inter-base-semibold">Pre-selected</h2>
                        <Controller
                            control={control}
                            name={`preSelected`}
                            render={({ field: { value, onChange } }) => {
                                return <Switch checked={value} onCheckedChange={onChange} />
                            }}
                        />
                    </div>
                </div>
                <Controller
                    control={control}
                    name={`product_id`}
                    render={({ field }) => {
                        return (
                            <NextSelect
                                label="Product option"
                                placeholder="Choose a product"
                                //@ts-ignore
                                options={productOptions}
                                isSearchable
                                isClearable
                                {...field}
                            />
                        )
                    }}
                />
            </div>
            <div className="flex justify-end w-full mt-4">
                <Button
                    size="small"
                    variant="secondary"
                    type="submit"

                >
                    Save
                </Button>
            </div>
        </form>

    );
};


const getDefaultValues = (kitConfigProduct: KitConfigProduct | undefined) => {
    let emptyKitConfigProduct: CreateKitConfigProductInput = {
        product_id: "",
        product_name: "",
        qtyNeeded: 1,
        preSelected: false,
        subTitle: "",
    }

    if (kitConfigProduct) {
        for (const [key, value] of Object.entries(kitConfigProduct)) {
            if (value && value !== undefined) {
                if (key === "product_id") {
                    //@ts-ignore
                    emptyKitConfigProduct["product_id"] = { value: kitConfigProduct.product.id, label: kitConfigProduct.product.title }
                } else if (key === "preSelected") {

                    emptyKitConfigProduct["preSelected"] = kitConfigProduct.preSelected
                }
                else {
                    emptyKitConfigProduct[key] = value

                }
            }

        }

    }

    return emptyKitConfigProduct
}


export default KitConfigProductForm;