import { Product, ProductTag } from "@medusajs/medusa"
import { Switch } from "@radix-ui/react-switch"
import React, { useEffect, useState } from "react"
import { Controller, FieldArrayWithId, useForm } from "react-hook-form"
import ArrayOfObjectsInput from "../../../domain/products/components/metadata-form/metadata-kit-config-form/array-of-objects-input"
import Button from "../../fundamentals/button"
import InputField from "../../molecules/input"
import Modal from "../../molecules/modal"
import { NextSelect } from "../../molecules/select/next-select"
import control from "../../molecules/select/next-select/components/control"

import Medusa from "../../../services/api"

type Props = {
    tag: string
    open: boolean
    onClose: () => void
}


const TagModal = ({ tag, open, onClose }: Props) => {
    const [featuredItems, setFeaturedItems] = useState<string[]>([]),
        [possableProducts, setPossableProducts] = useState<Product[]>(),
        [currentTag, setCurrentTag] = useState(""),
        [fullTag, setFullTag] = useState<ProductTag>()

    const getData = () => {
        Medusa.customRoutes.getProductsForTagId(tag).then(res => {
            console.log("res", res)
            setPossableProducts(res.data.filter(product => product.status === "published"))
            console.log(tag, featuredItems, possableProducts, fullTag)

        })
        Medusa.customRoutes.getTagForId(tag).then(res => {
            console.log("resf", res)
            const tempfeaturedItems = res.data.metadata && res.data.metadata.featuredItems ? res.data.metadata.featuredItems : []
            setFeaturedItems(tempfeaturedItems)
            setFullTag(res.data)
            console.log(tag, featuredItems, possableProducts, fullTag)

        })
    }
    useEffect(() => {

        if (tag !== currentTag) {
            console.log("current tags being set")
            setCurrentTag(tag)
            getData()
        }
    })

    const onReset = () => {
        onClose()
    }
    const onSubmit = () => {
        Medusa.customRoutes.addFeaturedItemsToTag(currentTag, featuredItems)
        onReset()
    }
    const handleProductClick = (product: Product) => {
        let tempfeaturedItems = [...featuredItems]
        if (featuredItems.includes(product.id)) {
            tempfeaturedItems = featuredItems.filter(id => id !== product.id)
        } else {
            if (tempfeaturedItems.length >= 3) {
                tempfeaturedItems.shift()
            }
            tempfeaturedItems.push(product.id)

        }
        setFeaturedItems(tempfeaturedItems)
    }

    const renderProducts = () => {
        if (possableProducts) {
            return (
                possableProducts.map(product => (
                    <Button variant={featuredItems.includes(product.id) ? "primary" : "secondary"} type="button" onClick={() => { handleProductClick(product) }}>
                        {product.title}
                    </Button>
                ))
            )
        } else {
            return <></>
        }
    }
    const renderFeaturedItems = () => {
        return featuredItems.map((id, i) => {
            let posOnStore = "Right"
            switch (i) {
                case 0:
                    posOnStore = "Banner"
                    break;
                case 1:
                    posOnStore = "Left"
                    break;
                default:
                    posOnStore = "Right"
                    break;
            }
            const fullProduct = possableProducts?.find((product) => product.id == id)
            if (fullProduct) {
                return (
                    <>
                        <p>
                            {fullProduct.title}: Shown as {posOnStore}
                        </p>
                        <hr />
                    </>


                )

            }
            return (<></>)

        })
    }

    return (
        <Modal open={open} handleClose={onReset} isLargeModal>
            <Modal.Body>
                <Modal.Header handleClose={onReset}>
                    <h1 className="inter-xlarge-semibold m-0">Edit "{fullTag?.value}" Tag Featured Items</h1>
                </Modal.Header>

                <Modal.Content>
                    <div className="mb-xlarge">
                        <h2 className="inter-large-semibold mb-2xsmall">Featured Products</h2>
                        <div className="flex flex-col">
                            {renderFeaturedItems()}
                        </div>

                        <p className="inter-base-regular text-grey-50 mb-large">
                            click on product to add or remove it.
                        </p>
                        <div className="flex">
                            {renderProducts()}
                        </div>

                    </div>

                </Modal.Content>
                <Modal.Footer>
                    <div className="flex gap-x-2 justify-end w-full">
                        <Button
                            size="small"
                            variant="secondary"
                            type="button"
                            onClick={onReset}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="primary"
                            type="submit"
                            onClick={onSubmit}

                        >
                            Save
                        </Button>
                    </div>
                </Modal.Footer>

            </Modal.Body>
        </Modal>
    )
}

export default TagModal
