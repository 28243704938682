import { Order, Product, ProductVariant, Region } from "@medusajs/medusa"

export type Option = {
  value: string
  label: string
}

export enum ProductStatus {
  DRAFT = "draft",
  PROPOSED = "proposed",
  PUBLISHED = "published",
  REJECTED = "rejected",
}

export type DateFilter = null | {
  gt?: string
  lt?: string
}

export enum TaxRateType {
  REGION = "region",
  RATE = "rate",
}

export type PaginationProps = {
  limit: number
  offset: number
}

export type Idable = { id: string;[x: string]: any }

export type Role = {
  value: "admin" | "member" | "developer"
  label: string
}

export type FormImage = {
  url: string
  name?: string
  size?: number
  nativeFile?: File
}

export interface DragItem {
  index: number
  id: string
  type: string
}
type SelfAsConfig = {
  selfAsConfig: boolean
  qtyNeeded: Number
  preSelected: boolean
  subTitle: string
  title: string
  mustSelect: boolean
  selectOne: boolean
}
type KitConfigProducts = {
  product_id: string
  product_name: string
  qtyNeeded: Number
  preSelected: boolean
  subTitle: string
}

export type ProductExtension = {
  unitQty: number
  physicalProductForReservation: boolean
  digitalProduct: boolean
  reservationProduct: boolean
  digitalProductEmailId: string
  physicalProductId: string
  preOrderId: string
  hidden: boolean
  kitConfigImage: string
  hoverImage: string

  kitConfigMaterialImage: string
  kitConfig: KitConfig[]
  selfAsConfig: SelfAsConfig
  features: string[],
  specifications: string[],
  docs: string[],
}


export interface ExtendedProduct extends Product {
  metadata: ProductExtension
}
export enum TopBarLevel {
  DEFAULT = "default",
  WARN = "warn",
  ALERT = "alert"
}

export enum TopBarPersistence {
  CLOSEABLE = "closeable",
  NAG = "nag",
  ALWAYS = "always"
}
export type TopBar = {
  id: string
  value: string
  persistence: TopBarPersistence
  level: TopBarLevel
  active: boolean
  startTime: string | null
  endTime: string | null
  metadata: Record<string, unknown> | null
  updatedAt: any
  createdAt: any
}

export type CreateTopBarInput = {
  value: string
  persistence: TopBarPersistence
  level: TopBarLevel
  active: boolean
  startTime: string | null
  endTime: string | null
  metadata: Record<string, unknown> | null
}
export type UpdateTopBarInput = {
  value?: string
  persistence?: TopBarPersistence
  level?: TopBarLevel
  active?: boolean
  startTime?: string | null
  endTime?: string | null
  metadata?: Record<string, unknown> | null
}
export type CreatePreOrderInput = {
  title: string
  canBuyRes: boolean
  canCompleteRes: boolean
  finished: boolean
  shipping: boolean
  estimatedShippingDate: string | null
  metadata: Record<string, unknown> | null
}
export type UpdatePreOrderInput = {
  title?: string
  canBuyRes?: boolean
  canCompleteRes?: boolean
  finished?: boolean
  shipping?: boolean
  estimatedShippingDate?: string | null
  metadata?: Record<string, unknown> | null
}
export type PreOrder = {
  id: string
  title: string
  canBuyRes: boolean
  canCompleteRes: boolean
  finished: boolean
  shipping: boolean
  estimatedShippingDate: string | null
  metadata: Record<string, unknown> | null
}

export enum DateOptions {
  day = "1",
  week = "7",
  twoWeeks = "14",
  month = '30',
  quarter = "90",
  year = "365",
}

export type GenralDashboardData = {
  lowStockProducts: ProductVariant[],
  outOfStockProducts: ProductVariant[],
  openOrders: Order[],
  openOrdersCount: number,
  openAssemblies: Order[],
  openAssembliesCount: number,
}
export type ThinGraphOrder = {
  date: string, total: number
}
export type DashboardGraphData = {
  salesByDay: ThinGraphOrder[], ordersByDay: Record<string, ThinGraphOrder[]>
}

export type ThinProductVariant = {
  productId: string
  id: string
  productTitle: string
  title: string
  thumbnail: string
  handle: string
  inventory_quantity: number
}
type AbstractSalesData = {
  count: number
}

export interface ThinProductVariantSalesData extends AbstractSalesData {
  thinVariant: ThinProductVariant
  count: number
}
export interface RegionSalesData extends AbstractSalesData {
  region: Region
  count: number
}


export type TimeDashboardData = {
  orderCount: number
  legacyOrderCount: number
  totalSales: number
  legacyTotalSales: number
  avgOrderValue: number
  legacyAvgOrderValue: number
  topSellingProducts: ThinProductVariantSalesData[]
  legacyTopSellingProducts: ThinProductVariantSalesData[]
  topRegion: RegionSalesData[]
  legacyTopRegion: RegionSalesData[]
  shippingCollected: number
  legacyShippingCollected: number
  newCustomerCount: number
  legacyNewCustomerCount: number
}


// new kitconfig stuff 


export type KitConfigProduct = {
  product_id: string
  product_name: string
  qtyNeeded: number
  preSelected: boolean
  subTitle: string
  id: string
  product: Product
  metadata?: Record<string, unknown>
}
export type KitConfig = {
  id: string
  topLevelConfig: boolean
  topLevelConfigTitle: string
  title: string
  mustSelect: boolean
  selectOne: boolean
  kitConfigProducts: KitConfigProducts[]
  products?: KitConfigProducts[]
  metadata?: Record<string, unknown>

}
export type PreBuiltKitConfig = {
  id: string
  title: string
  description: string
  kitConfig: KitConfig[];
  metadata?: Record<string, unknown>
}


export type CreateKitConfigProductInput = {
  product_id: string
  product_name: string
  qtyNeeded: number
  preSelected: boolean
  subTitle: string
  metadata?: Record<string, unknown>
}
export type UpdateKitConfigProductInput = {
  product_id?: string
  product_name?: string
  qtyNeeded?: number
  preSelected?: boolean
  subTitle?: string
  metadata?: Record<string, unknown>
}



export type CreateKitConfigInput = {
  title: string
  mustSelect: boolean
  selectOne: boolean
  kitConfigProducts: string[]
  topLevelConfig: boolean
  topLevelConfigTitle: string
  metadata?: Record<string, unknown>
}
export type UpdateKitConfigInput = {
  title?: string
  mustSelect?: boolean
  selectOne?: boolean
  kitConfigProducts?: string[]
  metadata?: Record<string, unknown>
}




export type CreatePreBuiltKitConfigInput = {
  title: string
  description: string
  kitConfig: string[];
  metadata?: Record<string, unknown>
}
export type UpdatePreBuiltKitConfigInput = {
  title?: string
  description?: string
  kitConfig?: string[];
  metadata?: Record<string, unknown>
}

export enum OrderTrackerStatus {
  NEEDS_TO_ORDER = "Needs To Be Orderd",
  ORDERED = "Ordered",
  RECIEVED = "Recieved",
  SHIPPED = "Shipped",
  NEED_TO_BE_LISTED = "Need To Be Listed"
}

export type OrderTracker = {
  id: string
  title: string
  oos: boolean
  currentlySold: boolean
  status: OrderTrackerStatus
  metadata: Record<string, unknown> | null
  updated_at: any
  created_at: any
}

export type CreateOrderTrackerInput = {
  title: string
  oos: boolean
  currentlySold: boolean
  status: OrderTrackerStatus
  metadata: Record<string, unknown> | null
}
export type UpdateOrderTrackerInput = {
  title?: string
  oos?: boolean
  currentlySold?: boolean
  status?: OrderTrackerStatus
  metadata?: Record<string, unknown> | null
}